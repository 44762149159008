import { useContext, useCallback, useEffect, useState, FC, useMemo } from 'react'
import Base from 'components/layout/base/Base'
import { Checkbox, Input, Dropdown, Button, Icon } from 'lm2storybook'
import useFetch from 'hooks/useFetch'
import { FilterContext } from 'context/FilterContext'
import { Article } from 'models/api/article'
import { ArticleResponse } from 'models/api/articleResponse'
import { DryingCreateRequest } from 'models/api/dryingCreateRequest'
import IPostOptions from 'interfaces/IPostOptions'
import { resourceUrl } from 'constants/apiconstants'
import PreviousDryings from 'pages/previousDryings/previousDryings'
import { t } from 'configs/i18n'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Item } from 'lm2storybook/dist/stories/interfaces/item'
import { useForm, Controller, ControllerRenderProps } from 'react-hook-form'
import useFormSettings from './useFormSettings'
import { TestBlob } from 'components/TestBlob'
import { Filter } from 'models/api/filter'
import { FilterResponse } from 'models/api/filterResponse'

/**
 * @interface IRegister
 * @description Form Interface
 * @property {string[]} styling - Styling
 */
export interface IRegister {
    styling?: string[]
}

/**
 * @module RegisterPage
 * @implements IForm
 * @description RegisterPage Component
 * @param props {IForm}
 * @returns FC
 * @exports RegisterPage {FC}
 */
const RegisterPage: FC = (props: IRegister) => {
    const { get, post, isError } = useFetch()
    const [queryString, setQueryString] = useState<string>('')
    const [facilityItems, setFacilityItems] = useState<Item[]>([])
    const [dryerItems, setDryerItems] = useState<Item[]>([])
    const [articlesItems, setArticleItems] = useState<Item[]>([])
    const [articles, setArticles] = useState<Article[]>([])
    const [partialDrying, setPartial] = useState<boolean>(false)
    const [containerItems, setContainerItems] = useState<Item[]>([])
    const [isSuccessCodeOnSave, setIsSuccessCodeOnSave] = useState<boolean | undefined>()
    const { defaultFormValues, fieldIsValid, rules, errorMessage } = useFormSettings()
    const {
        handleSubmit,
        reset,
        control,
        formState: { isValid, isSubmitting, submitCount, isSubmitSuccessful },
        getValues,
        setValue,
    } = useForm({
        mode: 'onTouched',
        defaultValues: defaultFormValues,
    })

    let { styling } = props
    const { setForceReloadPreviousDryings, setModalState } = useContext(FilterContext)

    /**
     * @description Get resource
     * @param resource
     * @param resourceId
     * @returns Promise<any>
     */
    const getResource = useCallback(
        async (resource: string, resourceId?: string) => {
            return await get<ArticleResponse>(resourceUrl(resource, resourceId))
        },
        [get]
    )

    /**
     * @description Create resource
     * @param resource
     * @param body
     * @returns Promise<any>
     */
    const createResource = useCallback(
        async (resource: string, body: string) => {
            const options: IPostOptions = {
                method: 'POST',
                body,
            }
            return await post(resourceUrl(resource), options)
        },
        [post]
    )

    /**
     * @description Get articles and set article items
     * @returns void
     */
    useEffect(() => {
        const getArticles = async () => {
            const response = await getResource('articles')
            setArticles(response.articles)
            if (response?.articles) {
                const items: Item[] = response.articles.map((x) => {
                    const item: Item = {
                        id: `${x.id}`,
                        name: x.name,
                        value: x.articleNumber,
                    }
                    return item
                })
                setArticleItems(items)
            } else {
                setModalState({
                    active: true,
                    message: t('fetchArticleError'),
                    title: t('systemMessage'),
                    showCloseButton: true,
                    closeButtonText: t('closePopUp'),
                })
                console.warn(t('fetchArticleError'))
            }
        }
        getArticles()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getResource, setArticles])

    /**
     * @description Get facilities on load
     * @returns void
     */
    useEffect(() => {
        const getFacilities = async () => {
            const FilterResponse = await get<FilterResponse>(resourceUrl('facilities'))
            const items: Item[] = FilterResponse.facilities.map((x) => {
                return {
                    id: `${x.id}`,
                    name: x.name,
                    value: '',
                }
            })
            setFacilityItems(items);
        }
        getFacilities()
        // Only run once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSelectedFacility = async (item: Item | null, field: ControllerRenderProps<any, any>) => {
        if (!item) {
            item = { id: 'all', name: 'All Facilities', value: '', selected: true }
        }
        if (item.id === 'all') {
            const updatedFacilities: Item[] = facilityItems.map((x) => {
                x.selected = false
                return x
            })
            setFacilityItems(updatedFacilities)
            field.onChange(undefined)
            field.onBlur()
            return
        }

        const response: Filter = await get(resourceUrl('facilities', `${item.id}`))

        if (isError(response)) {
            setModalState({ active: true, message: 'alertBackendRequestFailed', title: t('alertDefaultTitle') })
            return
        }

        if (response) {
            const facilities: Item[] = facilityItems.map((x) => {
                x.selected = x.id === item!.id
                return x;
            })
            setFacilityItems(facilities)
            if (response.dryers) {
                const items: Item[] = response.dryers.map((x: { id: any; name: any }) => {
                    return {
                        id: `${x.id}`,
                        name: x.name,
                        value: '',
                    }
                })
                setDryerItems(items)
            }
            if (response.containers) {
                const items: Item[] = [
                    { id: 'roundTrip', name: t('roundTrip'), value: 'true' },
                    ...response.containers.map((x: { id: any; name: any }) => {
                        return {
                            id: `${x.id}`,
                            name: x.name,
                            value: `${x.id}`,
                        }
                    }),
                ]
                setContainerItems(items)
            }
            field.onChange(item.id)
            field.onBlur()
        }
    }

    /**
     * @description Handle dryer selected
     * @param item
     * @param field
     */
    const handleDryerSelected = (item: Item | null, field: ControllerRenderProps<any, any>) => {
        if (!item) {
            const updatedDryers = dryerItems.map((x) => { 
                x.selected = false
                return x
            });
            setDryerItems(updatedDryers);
            return
        }

        field.onChange(item.id)
        field.onBlur()
        setDryerItems(setIsSelectedItem(dryerItems, item))
    }

    /**
     * @description Set selected item
     * @param array
     * @param selectedItem
     * @returns Item[]
     */
    const setIsSelectedItem = (array: Item[], selectedItem?: Item) => {
        const newArray = [...array]
        newArray.forEach((x) => {
            x.selected = x.id === selectedItem?.id ? true : false
        })

        return newArray
    }

    /**
     * @description Handle container selected
     * @param item
     * @param field
     * @returns void
     */
    const handleContainerSelected = (item: Item | null, field: ControllerRenderProps<any, any>) => {
        if (!item) {
            const updatedContainers = containerItems.map((x) => { 
                x.selected = false
                return x
            });
            setContainerItems(updatedContainers);
            return
        }

        field.onChange(item.id)
        field.onBlur()
        setContainerItems(setIsSelectedItem(containerItems, item))
    }

    /**
     * @description Handle article selected
     * @param item
     * @param field
     * @returns void
     */
    const handleArticleSelected = (item: Item | null, field: ControllerRenderProps<any, any>) => {
        if (!item) {
            const updatedArticles = articlesItems.map((x) => { 
                x.selected = false
                return x
            });
            setArticleItems(updatedArticles);
            return;
        }
        field.onChange(item.id)
        field.onBlur()

        setArticleItems(setIsSelectedItem(articlesItems, item))
        return;
    }

    /**
     * @description Submit form
     * @param args
     * @returns
     */
    const submitForm = async (args?: any) => {
        if (!isValid) {
            return
        }

        const formValues = getValues()
        const selectedFacilityId = facilityItems.find((x) => x.selected)?.id
        const selectedDryerId = dryerItems.find((x) => x.selected)?.id
        const selectedContainerId = containerItems.find((x) => x.selected)?.id
        const selectedArticleItemId = articlesItems.find((x) => x.selected)?.id
        const selectedArticleNumber = articles.find((x) => x.id === Number(selectedArticleItemId))?.articleNumber

        let errorMessage: string = ''
        if (!selectedFacilityId) {
            errorMessage = `${t('alertFacilitySelected')} \n`
        }
        if (!selectedDryerId) {
            errorMessage += `${t('alertNoDryerSelected')} \n`
        }

        if (!selectedContainerId) {
            errorMessage += `${t('alertNoContainerOrRoundTripSelected')} \n`
        }

        if (!formValues.operatorSign) {
            errorMessage += `${t('alertOperatorMissing')} \n`
        }

        if (!selectedArticleNumber) {
            errorMessage += `${t('alertNoArticleSelected')} \n`
        }

        if (errorMessage) {
            setModalState({
                active: true,
                message: errorMessage,
                title: t('alertDefaultTitle'),
                showCloseButton: true,
                closeButtonText: t('closePopUp'),
            })
            return
        }

        /**
         * @description Create payload
         * @returns DryingCreateRequest
         */
        const roundTripsIsSelected = formValues.containerId === 'roundTrip'
        const payload: DryingCreateRequest = {
            facilityId: Number(selectedFacilityId),
            operator: formValues.operatorSign,
            dryerId: Number(selectedDryerId),
            containerId: roundTripsIsSelected !== true ? Number(selectedContainerId) : undefined,
            moistureLevel: Number(formValues.moistureLevel),
            articleNumber: selectedArticleNumber,
            grainTempDryingZone: getOptionalNumber(formValues.grainTemperatureDryer),
            grainTempCoolerZone: getOptionalNumber(formValues.grainTempCoolerZone),
            comment: formValues.comment,
            specificWeight: getOptionalNumber(formValues.specificWeight),
            roundTrip: roundTripsIsSelected,
            overriddenTargetMoistureLevel: getOptionalNumber(formValues.overriddenTargetMoistureLevel),
            protein: getOptionalNumber(formValues.protein),
            setPoint: getOptionalNumber(formValues.setPoint),
            startDate: new Date(Date.now()),
            isPartialDrying: partialDrying,
        }

        const response = await createResource('dryings', JSON.stringify(payload))
        const success = typeof response === 'object' && response.errorMessage == null

        /**
         * @description Success & Handle error
         */
        if (!success) {
            setIsSuccessCodeOnSave(false)
            setModalState({
                active: true,
                message: errorMessage?.length !== 0 ? errorMessage : t('alertDryingFailedToSave'),
                title: t('systemMessage'),
                showCloseButton: true,
                closeButtonText: t('closePopUp'),
            })
        } else {
            setIsSuccessCodeOnSave(true)
            // reset form values
            setForceReloadPreviousDryings(true)
            setContainerItems(setIsSelectedItem(containerItems))
            setDryerItems(setIsSelectedItem(dryerItems))
            setArticleItems(setIsSelectedItem(articlesItems))
            setPartial(false)
            setModalState({
                active: true,
                message: t('alertDryingSaved'),
                title: t('systemMessage'),
                showCloseButton: true,
                closeButtonText: t('closePopUp'),
            })
        }
    }

    /**
     * @description Reset form values after successful submit
     * @returns void
     */
    useEffect(() => {
        const selectedFilter = facilityItems.find((x) => x.selected)
        if (isSubmitSuccessful && isSuccessCodeOnSave === true) {
            reset({ ...defaultFormValues })
            setValue('facilityId', `${selectedFilter?.id}`, { shouldTouch: true })
            setIsSuccessCodeOnSave(undefined)
        }
        // Should only be triggered on successful submit
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitSuccessful, isSuccessCodeOnSave])

    /**
     * @description Set facilityId for form
     * @returns void
     */
    useEffect(() => {
        const selectedFilter = facilityItems.find((x) => x.selected)
        if (selectedFilter?.id) {
            setValue('facilityId', `${selectedFilter?.id}`, { shouldTouch: true })
        }
        // Should only be triggered on init load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * @description fetch articles when filtering properties are changed
     */
    useEffect(() => {
        let queryString = ''
        const selectedFacility = facilityItems.find((x) => x.selected)
        const selectedDryer = dryerItems.find((x) => x.selected)

        if(selectedFacility?.id) {
            queryString += `&FacilityId=${selectedFacility.id}`
        }
        if(selectedDryer?.id) {
            queryString += `&DryerId=${selectedDryer.id}`
        }
        setQueryString(queryString);
    }, [facilityItems, dryerItems])

    /**
     * @description Get target moisture level from selected article
     * @returns number
     */
    const targetMoistureLevel = useMemo(() => {
        const item = articlesItems.find((x) => x.selected === true)
        const selectedArticle = articles.find((x) => x.id === Number(item?.id))
        return selectedArticle?.targetMoistureLevel
    }, [articlesItems, articles])

    /**
     * @description Render Component
     * @returns JSX.Element
     */
    return (
        <div className={['form-wrapper', styling].join(' ')}>
            <Base pageName="formPage">
                <h1>
                    {t('registerTitle')} <TestBlob />
                </h1>
                <div className="infoContainer">
                    <b>{t('targetWaterContent')}:</b> {targetMoistureLevel}
                </div>
                <div className="mandatory">
                    <Icon className="required" size="small" symbol="emergency" /> {t('requiredFields')}
                </div>
                <form noValidate onSubmit={handleSubmit(submitForm)}>
                    <div className="mdc-layout-grid__inner">
                        {/** First column */}
                        <div className="mdc-layout-grid__cell">
                            <div className="grid-form-item">
                                <Controller
                                    render={({ field, fieldState, formState }) => (
                                        <Dropdown
                                            id={field.name}
                                            description={`${t('facility')}`}
                                            data={facilityItems}
                                            icon="check"
                                            required={rules.facilityId.required.value}
                                            label={t('selectFacility')}
                                            listId="basic"
                                            onItemClick={(facility: Item | null) => handleSelectedFacility(facility, field)}
                                            useFilter
                                            isLoading={facilityItems?.length === 0 ? true : false}
                                            isValid={fieldIsValid(fieldState, submitCount)}
                                            errorMessage={errorMessage(fieldState, submitCount)}
                                        />
                                    )}
                                    rules={rules.facilityId}
                                    control={control}
                                    name="facilityId"
                                />
                            </div>

                            <div className="grid-form-item">
                                <Controller
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            id={field.name}
                                            description={`${t('dryer')}`}
                                            initData={{ compareKey: 'id', data: dryerItems }}
                                            data={dryerItems}
                                            icon="check"
                                            required={rules.dryerId.required.value}
                                            label={t('selectDrying')}
                                            listId="basic"
                                            onItemClick={(item: Item | null) => handleDryerSelected(item, field)}
                                            useFilter
                                            isValid={fieldIsValid(fieldState, submitCount)}
                                            errorMessage={errorMessage(fieldState, submitCount)}
                                        />
                                    )}
                                    rules={rules.dryerId}
                                    control={control}
                                    name="dryerId"
                                />
                            </div>

                            <div className="grid-form-item">
                                <Controller
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            id={field.name}
                                            description={`${t('article')}`}
                                            initData={{ compareKey: 'id', data: articlesItems }}
                                            data={articlesItems}
                                            icon="check"
                                            required={true}
                                            label={t('selectArticle')}
                                            listId="basic"
                                            onItemClick={(item: Item | null) => handleArticleSelected(item, field)}
                                            useFilter
                                            isValid={fieldIsValid(fieldState, submitCount)}
                                            errorMessage={errorMessage(fieldState, submitCount)}
                                        />
                                    )}
                                    rules={rules.articleId}
                                    control={control}
                                    name="articleId"
                                />
                            </div>

                            <div className="grid-form-item">
                                <Controller
                                    render={({ field, fieldState }) => (
                                        <Input
                                            isValid={fieldIsValid(fieldState, submitCount)}
                                            errorMessage={errorMessage(fieldState, submitCount)}
                                            id={field.name}
                                            label={t('overriddenTargetWaterContent')}
                                            name="inputBasic"
                                            placeholder={t('placeholderOverriddenTargetWaterContent')}
                                            type="number"
                                            stepping="0.1"
                                            min={rules.overriddenTargetMoistureLevel.min.value}
                                            max={rules.overriddenTargetMoistureLevel.max.value}
                                            hasLabel={true}
                                            value={field.value}
                                            onBlur={field.onBlur}
                                            onValueChanged={field.onChange}
                                        />
                                    )}
                                    rules={rules.overriddenTargetMoistureLevel}
                                    control={control}
                                    name="overriddenTargetMoistureLevel"
                                />
                            </div>

                            <div className="grid-form-item">
                                <Controller
                                    render={({ field, fieldState }) => (
                                        <Input
                                            isValid={fieldIsValid(fieldState, submitCount)}
                                            errorMessage={errorMessage(fieldState, submitCount)}
                                            id={field.name}
                                            label={`${t('waterLevel')}`}
                                            name={field.name}
                                            placeholder={t('placeholderMoisterLevel')}
                                            type="number"
                                            stepping="0.1"
                                            min={rules.moistureLevel.min.value}
                                            max={rules.moistureLevel.max.value}
                                            required={rules.moistureLevel.required.value}
                                            hasLabel={true}
                                            value={field.value}
                                            onBlur={field.onBlur}
                                            onValueChanged={field.onChange}
                                        />
                                    )}
                                    rules={rules.moistureLevel}
                                    control={control}
                                    name="moistureLevel"
                                />
                            </div>
                        </div>

                        {/** Second column */}
                        <div className="mdc-layout-grid__cell">
                            <div className="grid-form-item">
                                <Controller
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            description={`${t('toContainer')} / ${t('roundTrip')}`}
                                            initData={{ compareKey: 'id', data: containerItems }}
                                            data={containerItems}
                                            id={field.name}
                                            icon="check"
                                            label={t('addContainer')}
                                            listId="basic"
                                            required={rules.containerId.required.value}
                                            onItemClick={(item) => {
                                                handleContainerSelected(item, field)
                                            }}
                                            useFilter
                                            isValid={fieldIsValid(fieldState, submitCount)}
                                            errorMessage={errorMessage(fieldState, submitCount)}
                                        />
                                    )}
                                    rules={rules.containerId}
                                    control={control}
                                    name="containerId"
                                />
                            </div>

                            <div className="grid-form-item checkbox-container-space">
                                <Controller
                                    render={({ field, fieldState }) => (
                                        <Checkbox
                                            icon="check"
                                            id="isPartialDrying"
                                            label={t('isPartialDrying')}
                                            name="isPartialDrying"
                                            onValueChanged={() => {
                                                setPartial((partialDrying) => !partialDrying)
                                            }}
                                            value={partialDrying}
                                        />
                                    )}
                                    rules={rules.containerId}
                                    control={control}
                                    name="containerId"
                                />
                            </div>

                            <div className="grid-form-item">
                                <Controller
                                    render={({ field, fieldState }) => {
                                        return (
                                            <Input
                                                isValid={fieldIsValid(fieldState, submitCount)}
                                                errorMessage={errorMessage(fieldState, submitCount)}
                                                id="operatorSign"
                                                label={`${t('operator')}`}
                                                name="inputBasic"
                                                placeholder={t('addSignature')}
                                                type="text"
                                                required={rules.operatorSign.required.value}
                                                hasLabel={true}
                                                value={field.value}
                                                onBlur={field.onBlur}
                                                onValueChanged={field.onChange}
                                            />
                                        )
                                    }}
                                    rules={rules.operatorSign}
                                    control={control}
                                    name="operatorSign"
                                />
                            </div>

                            <div className="grid-form-item">
                                <Controller
                                    render={({ field, fieldState }) => (
                                        <Input
                                            isValid={fieldIsValid(fieldState, submitCount)}
                                            errorMessage={errorMessage(fieldState, submitCount)}
                                            id={field.name}
                                            label={t('comment')}
                                            name="comment"
                                            placeholder={t('addComment')}
                                            type="text"
                                            hasLabel={true}
                                            value={field.value}
                                            onBlur={field.onBlur}
                                            onValueChanged={field.onChange}
                                        />
                                    )}
                                    control={control}
                                    name="comment"
                                />
                            </div>
                        </div>
                        {/** Third column */}
                        <div className="mdc-layout-grid__cell">
                            <div className="grid-form-item">
                                <Controller
                                    render={({ field, fieldState }) => (
                                        <Input
                                            isValid={fieldIsValid(fieldState, submitCount)}
                                            errorMessage={errorMessage(fieldState, submitCount)}
                                            id={field.name}
                                            label={t('setPoint')}
                                            name="inputBasic"
                                            hasLabel={true}
                                            placeholder={t('placeholderSetPoint')}
                                            type="number"
                                            stepping="0.1"
                                            value={field.value}
                                            onBlur={field.onBlur}
                                            onValueChanged={field.onChange}
                                        />
                                    )}
                                    control={control}
                                    name="setPoint"
                                />
                            </div>

                            <div className="grid-form-item">
                                <Controller
                                    render={({ field, fieldState }) => (
                                        <Input
                                            isValid={fieldIsValid(fieldState, submitCount)}
                                            errorMessage={errorMessage(fieldState, submitCount)}
                                            id={field.name}
                                            label={t('dryZone')}
                                            name="inputBasic"
                                            hasLabel={true}
                                            placeholder={t('placeholderDryingZone')}
                                            type="number"
                                            stepping="0.1"
                                            min={rules.dryZone.min.value}
                                            max={rules.dryZone.max.value}
                                            value={field.value}
                                            onBlur={field.onBlur}
                                            onValueChanged={field.onChange}
                                        />
                                    )}
                                    rules={rules.dryZone}
                                    control={control}
                                    name="grainTemperatureDryer"
                                />
                            </div>

                            <div className="grid-form-item">
                                <Controller
                                    render={({ field, fieldState }) => (
                                        <Input
                                            isValid={fieldIsValid(fieldState, submitCount)}
                                            errorMessage={errorMessage(fieldState, submitCount)}
                                            id={field.name}
                                            label={t('coolZone')}
                                            name="inputBasic"
                                            hasLabel={true}
                                            placeholder={t('placeholderCoolingZone')}
                                            type="number"
                                            stepping="0.1"
                                            min={rules.coolZone.min.value}
                                            max={rules.coolZone.max.value}
                                            value={field.value}
                                            onBlur={field.onBlur}
                                            onValueChanged={field.onChange}
                                        />
                                    )}
                                    control={control}
                                    rules={rules.coolZone}
                                    name="grainTempCoolerZone"
                                />
                            </div>

                            <div className="grid-form-item">
                                <Controller
                                    render={({ field, fieldState }) => (
                                        <Input
                                            isValid={fieldIsValid(fieldState, submitCount)}
                                            errorMessage={errorMessage(fieldState, submitCount)}
                                            id={field.name}
                                            label={t('specificWeight')}
                                            name="inputBasic"
                                            placeholder={t('placeholderSpecificWeight')}
                                            type="number"
                                            stepping="0.1"
                                            hasLabel={true}
                                            value={field.value}
                                            onBlur={field.onBlur}
                                            onValueChanged={field.onChange}
                                        />
                                    )}
                                    control={control}
                                    name="specificWeight"
                                />
                            </div>

                            <div className="grid-form-item">
                                <Controller
                                    render={({ field, fieldState }) => (
                                        <Input
                                            isValid={fieldIsValid(fieldState, submitCount)}
                                            errorMessage={errorMessage(fieldState, submitCount)}
                                            id={field.name}
                                            label={t('protein')}
                                            name="inputBasic"
                                            placeholder={t('placeholderProtein')}
                                            type="number"
                                            stepping="0.1"
                                            hasLabel={true}
                                            value={field.value}
                                            onBlur={field.onBlur}
                                            onValueChanged={field.onChange}
                                        />
                                    )}
                                    control={control}
                                    name="protein"
                                />
                            </div>

                            <div className="grid-form-item"></div>
                        </div>
                    </div>
                    <div className="section-container right">
                        <Button
                            type="submit"
                            isLoading={isSubmitting}
                            className=""
                            icon="save"
                            label={t('saveChanges')}
                            primary
                            size="medium"
                        />
                    </div>
                </form>
            </Base>

            <div>
                <Base pageName="formPage">
                    <div className="">
                        <PreviousDryings sorting={false} editable={true} deleteEnabled={true} article={articlesItems.find(x => x.selected)?.name} queryString={queryString} />
                    </div>
                </Base>
            </div>
        </div>
    )
}

const getOptionalNumber = (stringValue: string): number | undefined => {
    if (!stringValue || stringValue === '') {
        return undefined
    }
    return Number(stringValue)
}

export default withAuthenticationRequired(RegisterPage)
