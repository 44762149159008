import * as React from 'react'
import { configuration } from 'configs/config';

/**
 * Add TEST to header if hosting environment is not production
 * @returns
 */
export const TestBlob: React.FC = () => {
    if (configuration?.environmentName === "prod") {
        return null;
    }
    return (
        <span className={'environment-text'}>
            OBS! TESTMILJÖ
        </span>
    )
}
