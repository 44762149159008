import { LanguageIdTypes } from 'interfaces/ILanguageId'

/**
 * Empty line to sync translation files.
 *
*/
const translation: LanguageIdTypes = {
    '401': 'Looks like your logged out. ',
    '404': 'The page is not found. Please try again later.',
    '500': 'Something went wrong with the server. Please try again later.',
    addComment: 'Add comment',
    addContainer: 'Add container',
    addRoundTrip: 'Add round trip',
    addSignature: 'Add signature',
    admin: 'Admin',
    adminPageTitle: 'Administration',
    alertBackendRequestFailed: 'Failed to get data from server. Please try again.',
    alertBothContainerAndRoundTripSelected: 'Select either container or round trip, not both',
    alertDefaultTitle: 'Message',
    alertDryingSaved: 'Drying saved!',
    alertDryingFailedToSave: 'Failed so save drying, please try again',
    alertFacilitySelected: 'No facility is selected.',
    alertFailedSavingDrying: 'Failed to save the drying',
    alertNoArticleSelected: 'No article selected',
    alertNoContainerOrRoundTripSelected: 'Select container or round trip',
    alertNoDryerSelected: 'No Dryer selected',
    alertOperatorMissing: 'Operator/signature missing',
    previousDryingsSaveError: 'There are errors in the table. Correct these before saving.',
    alertInvalidForm: 'There are errors in the form. Correct these before saving.',
    applicationName: 'Drying Log',
    article: 'Article',
    closePopUp: 'Close',
    columnLabels: {
        articleMoistureTargetLevel: 'Moist. target lvl',
        articleName: 'Article',
        comment: 'Comment',
        container: 'Container',
        dryerName: 'Dryer',
        facilityName: 'Facility',
        grainTemp: 'Grain temp',
        grainTempCoolerZone: 'Temp cooler',
        grainTempDryingZone: 'Temp drying',
        id: 'ID',
        moistureLevel: 'Moist. lvl',
        overriddenTargetMoisture: 'Overr. target moist. lvl',
        protein: 'Protein',
        setPoint: 'Set point',
        specificWeight: 'Weight',
        startDate: 'Start time',
        isPartialDrying: 'Partial drying'
    },
    facilities: 'Facility',
    'facilityForm.externalId': 'External ID',
    'facilityForm.externalIdPlaceholder': 'Enter external ID',
    'facilityForm.externalIdRequired': 'External ID is required',
    'facilityForm.facilityId': 'Facility ID',
    'facilityForm.facilityIdPlaceholder': 'Enter facility ID',
    'facilityForm.facilityIdRequired': 'Facility ID is required',
    'facilityForm.facilityName': 'Facility name',
    'facilityForm.facilityNamePlaceholder': 'Enter facility name',
    'facilityForm.facilityNameRequired': 'Facility name is required',
    'facilityForm.region': 'Region',
    'facilityForm.regionPlaceholder': 'Enter region',
    'facilityForm.regionRequired': 'Region is required',
    'facilityForm.saveError': 'Failed to save facility',
    'facilityForm.saveSuccess': 'Facility saved!',
    'facilityForm.status': 'Status',
    'facilityForm.statusPlaceholder': 'Select status',
    'facilityForm.statusRequired': 'Status is required',
    'facilityForm.status.active': 'Active',
    'facilityForm.status.inactive': 'Inactive',
    comment: 'Comment',
    confirmDeleteMessage: 'Do you want to delete this log?',
    coolZone: 'Grain temperature cooling zone',
    dateTime: 'Date/time:',
    defaultError: 'Oooh noo!, Error, Please try later.',
    dryer: 'Dryer',
    dryZone: 'Air temperature drying zone',
    facility: 'Facility',
    fetchArticleError: 'Failed to fetch articles',
    home: 'Hem',
    listDryings: 'Log',
    listDryingsTitle: 'Log',
    loadMore: 'Load more',
    login: 'Login',
    loginDescription: 'Please login with your Lantmännen e-mail address.',
    loginError: 'Something went wrong with the log ing, try agin..',
    loginLoading: 'Loading...',
    logout: 'Log out',
    operator: 'Operator (Signature)',
    overriddenTargetWaterContent: 'Overridden target water content',
    placeholderCoolingZone: 'Enter a value between 0-50',
    placeholderDryingZone: 'Enter a value between 40-95',
    placeholderMoisterLevel: 'Enter a value between 5-30',
    placeholderOverriddenTargetWaterContent: 'Enter a value between 5-30',
    placeholderProtein: 'Enter a value',
    placeholderSetPoint: 'Enter a value',
    placeholderSpecificWeight: 'Enter a value',
    protein: 'Protein',
    register: 'Register',
    registerTitle: 'Register drying',
    requiredFields: 'F.Y.I Mandatory fields!',
    roundTrip: 'Round trip',
    saveChanges: 'Save',
    selectArticle: 'Select article',
    selectDrying: 'Select drying',
    selectFacility: 'Select facility',
    setPoint: 'Set point',
    somethingWrong: 'Oooops! Something went wrong.',
    specificWeight: 'Specific weight',
    systemMessage: 'System message',
    targetWaterContent: 'Target water content',
    toContainer: 'To container',
    waterLevel: 'Water level',
    editFacility: 'Edit facility',
    addFacility: 'Add facility',
    saveFacility: 'Save facility',
    saveEdit: 'Save edit',
    goBackToList: 'Back to list',
    dryers: 'Dryers',
    containers: 'Containers',
    add: 'Add',
    name: 'Name',
    isPartialDrying: 'Partial Drying',
    yes: 'Yes',
    no: 'No',
}

export default translation
