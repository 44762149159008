import { useFormContext } from 'react-hook-form'
import { Input } from 'lm2storybook'
import { Controller, ControllerFieldState, ControllerProps } from 'react-hook-form'
import { FC } from 'react'

/**
 * @description Interface Controller Input Props
 */
export interface IControllerInputProps {
    rules?: ControllerProps['rules']
    label: string
    name: string
    disabled?: boolean
}

/**
 * @description Controller Input
 * @returns
 */
export const ControllerInput: FC<IControllerInputProps> = ({ rules, label, name, disabled }) => {
    /**
     * @description Form methods
     */
    const methods = useFormContext()
    const {
        control,
        formState: { submitCount },
    } = methods

    /**
     * @description Field is valid
     * @param fieldState
     * @returns
     */
    const fieldIsValid = (fieldState: ControllerFieldState) =>
        !fieldState.isTouched && submitCount === 0 ? undefined : !fieldState.invalid

    /**
     * @description Field error message
     * @param fieldState
     * @returns
     */
    const fieldErrorMessage = (fieldState: ControllerFieldState) => {
        if (!fieldState.isTouched && submitCount === 0) {
            return
        }
        if (submitCount > 0) {
            return fieldState.error?.message
        }
    }

    /**
     * @description Render
     */
    return (
        <div className="mdc-layout-grid__cell">
            <Controller
                render={({ field, fieldState }) => (
                    <Input
                        isValid={fieldIsValid(fieldState)}
                        errorMessage={fieldErrorMessage(fieldState)}
                        label={label}
                        type={'text'}
                        id={field.name}
                        hasLabel
                        value={field.value}
                        onBlur={field.onBlur}
                        onValueChanged={field.onChange}
                        disabled={disabled}
                    />
                )}
                rules={rules}
                control={control}
                name={name}
            />
        </div>
    )
}
