import ModalContainer from 'components/modal/modalContainer'
import useAuthUser from 'hooks/useAuthUser'
import { useRouteConfiguration } from 'hooks/useRouteConfiguration'
import Navbar from 'lm2storybook/dist/stories/components/molecules/navbar/Navbar'
import { FC, useEffect, useState } from 'react'

const NavbarContainer: FC = () => {
    const { navBarRoutes,  navBarRoutesAdmin} = useRouteConfiguration()
    const [routes , setRoutes] = useState<React.JSX.Element| undefined>()
    const { isAuthenticated, isAdmin } = useAuthUser()

    useEffect(() => {
        if (isAuthenticated && isAdmin) {
            setRoutes(<Navbar key={'isAdmin'} routes={navBarRoutesAdmin} />)
        } else {    
            setRoutes(<Navbar key={'isNotAdmin'} routes={navBarRoutes} />)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, isAdmin])
    return (
        <>
            <ModalContainer />
            {routes}
        </>
    )
}
export default NavbarContainer
