import useFetch from './useFetch'
import { resourceUrl } from 'constants/apiconstants'
import { Filter, FilterUpsertRequest } from 'models/api/filter'
import { FilterResponse, isFilterResponse } from 'models/api/filterResponse'

const useFilterAdminApi = () => {
    const adminFacilityUrl = 'admin/facilities'
    const adminContainerUrl = 'admin/containers'
    const adminDryerUrl = 'admin/dryers'
    const { get, isLoading, put, post } = useFetch()

    const isSuccess = (response: any): boolean => {
        return typeof response === 'object' && response.errorMessage == null
    }

    const fetchFilters = async (): Promise<Filter[]> => {
        const response = await get<FilterResponse>(resourceUrl(`${adminFacilityUrl}`))

        if (isFilterResponse(response)) {
            return response.facilities
        }
        return []
    }

    const fetchFilter = async (facilityId: number): Promise<Filter> =>
        await get<Filter>(resourceUrl(`${adminFacilityUrl}/${facilityId}`))

    const updateFilter = async (id: number, facility: FilterUpsertRequest) => {
        const response = await put(resourceUrl(`${adminFacilityUrl}/${id}`), { body: JSON.stringify(facility) })
        return response
    }

    const createFilter = async (facility: FilterUpsertRequest) => {
        const response = await post(resourceUrl(`${adminFacilityUrl}`), { body: JSON.stringify(facility) })

        if (isSuccess(response)) {
            return response.json()
        }
        return response
    }

    const getContainers = async (facilityId: number): Promise<any> => {
        const response = await get<any>(resourceUrl(`${adminContainerUrl}?facilityId=${facilityId}`))

        if (isSuccess(response) && response.containers) {
            return response.containers
        }
        return []
    }

    const updateContainer = async (id: number, payload: any) => {
        const response = await put(resourceUrl(`${adminContainerUrl}/${id}`), { body: JSON.stringify(payload) })
        return response
    }

    const createContainer = async (payload: any) => {
        const response = await post(resourceUrl(`${adminContainerUrl}`), { body: JSON.stringify(payload) })

        if (isSuccess(response)) {
            return response.json()
        }
        return response
    }

    const getDryers = async (facilityId: number): Promise<any> => {
        const response = await get<any>(resourceUrl(`${adminDryerUrl}?facilityId=${facilityId}`))

        if (isSuccess(response) && response.dryers) {
            return response.dryers
        }
        return []
    }

    const updateDryer = async (id: number, payload: any) => {
        const response = await put(resourceUrl(`${adminDryerUrl}/${id}`), { body: JSON.stringify(payload) })
        return response
    }

    const createDryer = async (payload: any) => {
        const response = await post(resourceUrl(`${adminDryerUrl}`), { body: JSON.stringify(payload) })

        if (isSuccess(response)) {
            return response.json()
        }
        return response
    }

    return {
        fetchFilters,
        isLoading,
        fetchFilter,
        updateFilter,
        createFilter,
        updateContainer,
        createContainer,
        updateDryer,
        createDryer,
        getContainers,
        getDryers,
    }
}
export default useFilterAdminApi
