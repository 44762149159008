import { useState } from 'react';
import { Tooltip } from 'lm2storybook';

const RenderToolTip = (input: string): JSX.Element => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const textIsTooLong = input.length > 20;

    return (
        <span
            onMouseOver={() => setIsTooltipOpen(true)}
            onMouseLeave={() => setIsTooltipOpen(false)}>
            {textIsTooLong ?
                <>
                    <Tooltip
                        isOpen={isTooltipOpen}
                        message={input}
                        className="information"
                        position="top"
                    />
                    {input.substring(0, 20) + (input.length > 20 ? "..." : "")}
                </>
                :
                input
            }
           
        </span>
    );
};

export default RenderToolTip;
