import { useCallback, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useErrorBoundary } from 'react-error-boundary'

import IPostOptions from '../interfaces/IPostOptions'
import IPutOptions from '../interfaces/IPutOptions'
import IDeleteOptions from '../interfaces/IDeleteOptions'
import { configuration } from '../configs/config'
import { useNavigate } from 'react-router-dom'

/**
 * @type {IOptions} options - Options for the fetch
 */
type IOptions = IPostOptions | IPutOptions | IDeleteOptions

/**
 * @function useFetch
 * @description A hook that returns a function to fetch data from the API
 * @returns
 */
const useFetch = (): {
    isError: (response: any) => boolean
    get: <T>(url: string, options?: IOptions) => Promise<T>
    post: (url: string, options: IPostOptions) => Promise<any>
    put: (url: string, options: IPutOptions) => Promise<any>
    deleteRequest: (url: string, options: IDeleteOptions) => Promise<any>
    isLoading: boolean
} => {
    const { loginWithRedirect, getAccessTokenSilently } = useAuth0()
    const [isLoading, setIsLoading] = useState(false)
    const { showBoundary } = useErrorBoundary()
    const navigate = useNavigate()

    /**
     * Checks wether the response is an error or not
     * @param response Response object
     * @returns True if error, otherwise false
     */
    const isError = useCallback((response: any): boolean => {
        return typeof response !== 'object' && (response < 200 || response >= 300)
    }, [])

    /**
     * @function runner
     * @description A function that fetches data from the API
     * @param {string} url - The url to fetch from
     * @param {IOptions} options - Options for the fetch
     * @returns
     */
    const runner = useCallback(
        async (url: string, options?: IOptions) => {
            setIsLoading(true)
            let result: any

            /**
             * @constant accessToken
             * @description The accesstoken from auth0
             * We will first try to get an accesstoken from auth0
             * @type {string}
             * @returns {string} || {error}
             * @throws {error}
             */
            try {
                const accessToken = await getAccessTokenSilently().catch((error) => {
                    if (error.error !== 'login_required') {
                        return error.error
                    }
                })

                /**
                 * If we don't have an accesstoken we will prompt the user to login
                 */
                if (!accessToken) {
                    /**
                     * @constant origin
                     * @description The origin of the current window
                     * If no accesstoken we check if we should login the user automatically
                     * If window.location.origin is an URL specificed for automatic login -
                     * the user will be logged in without clicking "Login"-btn. Otherwise the
                     * login page is presented.
                     *
                     * Reason for approach: Automatically login caused major delay when feedsmart
                     * runs stand alone. When running feedsmart integrated to LM2 automatic login
                     * works fine.
                     * @type {string}
                     * @returns {string}
                     */
                    const { origin } = window.location
                    if (configuration.autoLoginAtUrls.includes(origin)) {
                        await loginWithRedirect()
                    }
                }

                /**
                 * @constant headers
                 * @description The headers for the fetch
                 * If we have an accesstoken we will add it to the headers
                 * @type {IHeaders}
                 * @returns {IHeaders}
                 */
                const headers = {
                    ...(options?.headers instanceof Array ? options.headers : []),
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                }

                /**
                 * @constant requestOptions
                 * @description The options for the fetch
                 * * We will then add the headers to the options object
                 * We use the spread operator to add the headers to the options object
                 * and then we add the options object to the requestOptions object
                 * to make sure we don't mutate the options object
                 * @type {IOptions}
                 * @returns {IOptions}
                 */
                const requestOptions = {
                    headers,
                    ...options,
                }

                /**
                 * @constant result
                 * @description The result from the fetch
                 * @type {Response}
                 * @returns {Response}
                 */
                result = await fetch(url, requestOptions)

                if (!result.ok) {
                    /**
                     * @constant result
                     * @description The result from the fetch
                     * If we receive an error from api response we will most likely
                     * want to prompt the user with issue with request. We therefore send
                     * the status code back and handle the issue where the request is sent.
                     * @type {Response}
                     * @returns {Response}
                     * @throws {error}
                     *
                     */
                    if (result.status === 401 || result.status === 403 || result.status === 404) {
                        navigate(`/error/?error=${result.status}`)
                        return ''
                    }

                    // 500, ...
                    return result.status
                }

                if (!options && result.ok) {
                    // Next Line for debugging purposes, since you can't do json() on undefined,
                    // this will simulate a crash or if the database is down.
                    // result = undefined

                    /**
                     * @constant result
                     * @description The result from the fetch
                     * It's only the get method that doesn't have any options
                     * object, so if the options object is absent it can only
                     * be a get request, therefor we'll want to parse the data
                     * and return that instead.
                     * @type {Response}
                     * @returns {Response}
                     * @throws {error}
                     */
                    result = await result.json()
                }

                /**
                 * @constant setIsLoading
                 * @description Set isLoading to false
                 * We will then set isLoading to false
                 * to indicate that the request is done
                 * and we can render the data
                 * @type {boolean}
                 * @returns {boolean}
                 * @throws {error}
                 */
                setIsLoading(false)

                /**
                 * @constant result
                 * @description The result from the fetch
                 *If an option object is present it must be a POST, PUT
                 * or DELETE and no data is return really, or we are
                 * at least not interested in it, that's why we just
                 * return the result of the resolved promise.
                 * @type {Response}
                 *
                 */
                return result
            } catch (error: any) {
                /**
                 * @constant setIsLoading
                 * @description Set isLoading to false
                 * We will then set isLoading to false
                 * to indicate that the request is done
                 * and we can render the data
                 * @type {boolean}
                 * @returns {boolean}
                 * @throws {error}
                 */
                showBoundary(error)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [getAccessTokenSilently, loginWithRedirect, showBoundary]
    )

    /**
     * @function get
     * @description A function that fetches data from the API
     * @param url
     * @returns {Promise<any>}
     */
    const get = useCallback(async (url: string) => runner(url), [runner])

    /**
     * @function post
     * @description A function that posts data to the API
     * @param url
     * @param options
     * @returns {IPostOptions}
     */
    const post = async (url: string, options: IPostOptions) => runner(url, {...options, method: 'POST'})

    /**
     * @function put
     * @description A function that puts data to the API
     * @param url
     * @param options
     * @returns {IPutOptions}
     */
    const put = async (url: string, options: IPutOptions) => runner(url, { ...options, method: 'PUT' })

    /**
     * @function deleteRequest
     * @description A function that deletes data from the API
     * @description Delete is a reserved word in javascript, so we need to rename the function
     * @param url
     * @param options
     * @returns  {IDeleteOptions}
     */
    const deleteRequest = async (url: string, options: IDeleteOptions) => runner(url, options)

    return { get, post, put, deleteRequest, isLoading, isError }
}

export default useFetch
