import { resourceUrl } from 'constants/apiconstants'
import useFetch from 'hooks/useFetch'
import { t } from 'configs/i18n'
import { Button, Modal } from 'lm2storybook'
import * as React from 'react'

export interface IProps {
    settings: { isOpen: boolean; id?: string }
    onClose: () => void
    onConfirm: () => void
}

const ConfirmDeleteModal: React.FC<IProps> = ({ settings, onClose, onConfirm }) => {
    const { deleteRequest, isLoading } = useFetch()

    const handleDeleteConfirm = async () => {
        const response = await deleteRequest(resourceUrl(`dryings/${settings.id}`), { method: 'DELETE' })
        const success = typeof response === 'object' && response.errorMessage == null

        if (success) {
            return onConfirm()
        }
        return onClose()
    }
    return (
        <Modal headerText={t('confirmDeleteMessage')} {...settings} onClose={() => onClose()}>
            <>
                <div className="confirmDelete">
                    <Button className="delete" icon="delete_forever" primary={true} isLoading={isLoading} label="Delete" onClick={() => handleDeleteConfirm()} />
                    <Button icon="close" primary label="Cancel" onClick={() => onClose()} />
                </div>
            </>
        </Modal>
    )
}
export default ConfirmDeleteModal
