import { DryingTableData } from 'types/dryings'
import { columnNames } from './columNames'

/**
 * @description Defines columns in table
 */
export const columnData: {
    key: keyof DryingTableData
    label: string
}[] = [
    {
        key: columnNames.id,
        label: 'columnLabels.id',
    },
    {
        key: columnNames.startDate,
        label: 'columnLabels.startDate',
    },
    {
        key: columnNames.dryerName,
        label: 'columnLabels.dryerName',
    },
    {
        key: columnNames.facilityName,
        label: 'columnLabels.facilityName',
    },
    {
        key: columnNames.isPartialDrying,
        label: 'columnLabels.isPartialDrying',
    },
    {
        key: columnNames.articleName,
        label: 'columnLabels.articleName',
    },
    {
        key: columnNames.articleMoistureTargetLevel,
        label: 'columnLabels.articleMoistureTargetLevel',
    },
    {
        key: columnNames.overriddenTargetMoisture,
        label: 'columnLabels.overriddenTargetMoisture',
    },
    {
        key: columnNames.moistureLevel,
        label: 'columnLabels.moistureLevel',
    },
    {
        key: columnNames.comment,
        label: 'columnLabels.comment',
    },
    {
        key: columnNames.grainTemp,
        label: 'columnLabels.grainTemp',
    },
    {
        key: columnNames.grainTempCoolerZone,
        label: 'columnLabels.grainTempCoolerZone',
    },
    {
        key: columnNames.grainTempDryingZone,
        label: 'columnLabels.grainTempDryingZone',
    },
    {
        key: columnNames.setPoint,
        label: 'columnLabels.setPoint',
    },
    {
        key: columnNames.specificWeight,
        label: 'columnLabels.specificWeight',
    },
    {
        key: columnNames.protein,
        label: 'columnLabels.protein',
    },
    {
        key: columnNames.container,
        label: 'columnLabels.container',
    },
]
