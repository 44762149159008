/**
 * @description Configuration Interface
 * @interface IConfiguration
 * @property {string} backendBaseUrl - Backend Base URL
 * @property {string} authDomain - Auth Domain
 * @property {string} authClientId - Auth Client ID
 * @property {string} authAudience - Auth Audience
 * @property {string} autoLoginAtUrls - Auto Login At URLs
 * @property {string} claimsName - Auth claims name (whrere to find AD Groups)
 * @property {string} superAdminClaimsValue - Name of AD group that give super admin access
 */
interface IConfiguration {
  backendBaseUrl: string;
  authDomain: string;
  authClientId: string;
  authAudience: string;
  autoLoginAtUrls: string;
  claimsName: string;
  superAdminClaimsValue: string;
  environmentName: string;
}

/**
 * @global Global Interface
 * @description Configuration Globals
 * @interface Window
 */
declare global {
  interface Window {
    envConfig: IConfiguration;
  }
}

/**
 * @description Configuration Object
 */
let envConfig = (window as Window).envConfig;

/**
 * @description Configuration Object Authentication
 * @tips Override in development mode by using a ".env.development.local" file
 */
if (process.env.REACT_APP_BACKEND_BASE_URL !== undefined) {

    envConfig.backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL!;
    envConfig.authDomain = process.env.REACT_APP_AUTH_DOMAIN!;
    envConfig.authClientId = process.env.REACT_APP_AUTH_CLIENT_ID!;
    envConfig.authAudience = process.env.REACT_APP_AUTH_AUDIENCE!;
    envConfig.autoLoginAtUrls = process.env.REACT_APP_LOGIN_AT_URLS!;
    envConfig.claimsName = process.env.REACT_APP_CLAIMS_NAME!;
    envConfig.superAdminClaimsValue = process.env.REACT_APP_SUPERADMIN_CLAIMS_VALUE!;
    envConfig.environmentName = process.env.REACT_APP_ENVIRONMENT_NAME!;

    console.log('Using settings from environment variables');
}

export let configuration = envConfig;
