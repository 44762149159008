import { useAuth0 } from '@auth0/auth0-react'
import Base from 'components/layout/base/Base'
import { Button, Table } from 'lm2storybook'
import { t } from 'configs/i18n'
import { Link } from 'react-router-dom'
import { TestBlob } from 'components/TestBlob'

/**
 * @description Home page
 * @returns {JSX.Element}
 */
const Home = (): JSX.Element => {
    const { isLoading, isAuthenticated, error, user, loginWithRedirect } = useAuth0()

    /**
     * @description Home page render
     */
    return (
        <Base pageName="home">
            <div className="login-wrapper">
                {isAuthenticated ? (
                    <>
                        <div className="mdc-layout-grid remove-padding">
                            <div className="mdc-layout-grid__inner remove-padding">
                                <div className="mdc-layout-grid__cell--span-2">
                                    <h1>{t('applicationName')} <TestBlob /> </h1>
                                    <span className="text">
                                        {user?.name} ({user?.email})
                                    </span>
                                </div>

                                <div className="mdc-layout-grid--align-right mdc-layout-grid__cell--span-10">
                                    <Link to={'/logout'}>
                                        <Button label={t('logout')} />
                                    </Link>
                                </div>

                                <div className="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--align-bottom">
                                    <h3>Torkning av spannmål, oljeväxter och trindsäd</h3>
                                    <p>
                                        För att kunna avgöra hur en vara ska torkas behöver man känna till vad varan ska
                                        användas till, vilka krav kunden <br />
                                        har när det gäller vattenhalt och hur länge varan ska lagras​.
                                        <br />
                                        För att uppfylla våra kunders krav och minimera övertorkning sammanställs
                                        <br />
                                        målvattenhalter som syns överst i ”Registrera Torkning” när artikel är vald.
                                    </p>

                                    <p>
                                        När annan målvattenvalt än ordinarie gäller, fyll i överstyrd målvattenhalt
                                        under ”Överstyrd målvattenhalt”.
                                        <br />I samband med torkning/lagerläggning skall siloprov tas ut och värden ska
                                        registreras i ”Registrera torkning”
                                    </p>
                                    <h4 className="padding-top-15">Registrering ska göras minimum enligt nedan: </h4>

                                    <Table
                                        classNames="displayAllCells"
                                        columnData={[
                                            {
                                                key: 'typ',
                                                label: 'Typ av torkstyrning',
                                            },
                                            {
                                                key: 'max',
                                                label: 'Max antal timmar mellan provtagningar',
                                            },
                                        ]}
                                        rowData={[
                                            {
                                                typ: 'Manuellt styrd, ingen automatik alls',
                                                max: '2 h',
                                            },
                                            {
                                                typ: 'Halv Automatisk, ställa in börvärde manuellt och sedan slå på automatik',
                                                max: '4 h',
                                            },
                                            {
                                                typ: 'Helautomatisk, typ Tornum IDC',
                                                max: '6 h',
                                            },
                                        ]}
                                    />

                                    <h5 className="padding-top-15">
                                        OBS! Vid stora variationer på vattenhalt eller andra osäkerheter i torken bör
                                        prover tas oftare.
                                    </h5>

                                    <p>
                                        Uppföljning av torkvärden görs via Power BI <br />
                                        För mer information gå till Leda Lantbruk
                                        <br />
                                        Torkning av spannmål, oljeväxter och trindsäd (sharepoint.com)
                                    </p>

                                    <h4 className="padding-top-15">Definitioner i rapporten:</h4>
                                    <Table
                                        classNames="displayAllCells padding-top-15 breakRows"
                                        columnData={[
                                            {
                                                key: 'typ',
                                                label: 'Värden att fylla i',
                                            },
                                            {
                                                key: 'definition',
                                                label: 'Definition',
                                            },
                                        ]}
                                        rowData={[
                                            {
                                                typ: 'Till behållare',
                                                definition: 'Den behållare torkad vara körs till just nu',
                                            },
                                            {
                                                typ: 'Vattenhalt',
                                                definition: 'Provtagen vattenhalt i %',
                                            },
                                            {
                                                typ: 'Deltorkning',
                                                definition: 'Torkar ned från en hög vattenhalt till en lägre halt för att mellanlagra innan sluttorkning till målvattenhalt.',
                                            },
                                            {
                                                typ: 'Rundkörning',
                                                definition: 'Har inte nått målvattenhalten utan behöver torka materialet ytterligare och detta sker i en följd utan mellanlagring',
                                            },
                                            {
                                                typ: 'Börvärde',
                                                definition:
                                                    'Börvärde är önskat värde eller målvärde för en viss parameter eller variabel som styrs under torkprocessen. \nBörvärde mäts vanligtvis med hjälp av en eller flera av följande parametrar: temperatur, fuktighet, kapacitet, materialtjocklek, luftflöde eller tid. \nInom Lantmännen är börvärde vanligtvis kritisk i kombination med temperatur om vi vill träffa rätt vattenhalt.',
                                            },
                                            {
                                                typ: 'Lufttemperatur torkzon',
                                                definition: 'Avläst temperatur luft i torkzon',
                                            },
                                            {
                                                typ: 'Spannmåltemperatur kylzon',
                                                definition: 'Avläst temperatur på spannmål i kylzon',
                                            },
                                            {
                                                typ: 'Rymdvikt',
                                                definition: 'Avläst rymdvikt kg/l',
                                            },
                                            {
                                                typ: 'Protein',
                                                definition: 'Avläst proteinhalt',
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <h1 className="no-margin  margin-bottom-20">{t('applicationName')}</h1>

                        {error && !isLoading && <span className="login-error">{t('loginError')}</span>}

                        {isLoading ? (
                            <span className="text">{t('loginLoading')}</span>
                        ) : (
                            <>
                                <span className="text">{t('loginDescription')}</span>
                                <p>
                                    <Button onClick={() => loginWithRedirect()} label={t('login')} />
                                </p>
                            </>
                        )}
                    </>
                )}
            </div>
        </Base>
    )
}

export default Home
