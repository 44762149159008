import { FC } from 'react'
import { DefaultRoutes } from 'routing/defaultRoutes'
import { HashRouter } from 'react-router-dom'
import NavbarContainer from 'components/layout/NavbarContainer'

import 'material-icons/iconfont/material-icons.css';

/**
 * @module App
 * @description App - Wrapper
 * @borrows HashRouter, Routes, Route
 * @returns JSX.Element
 * @exports App {JSX.Element}
 */
export const App: FC = () => {
    return (
        <div className="app tab-navigation">
            <div className="app-wrapper">
                <HashRouter>
                    <NavbarContainer />
                    <DefaultRoutes />
                </HashRouter>
            </div>
        </div>
    )
}
