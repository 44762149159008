import { t } from 'configs/i18n'
import useFilterAdminApi from 'hooks/useFilterAdminApi'
import { FC, useEffect, useState } from 'react'
import { Button, Spinner, Table } from 'lm2storybook'
import { FilterDetailTableModal } from './FilterDetailTableModal'

/**
 * @description Facility Item Interface
 */
export interface ITableItem {
    id: number
    name: string
    status: string
    rowId?: string
}

/**
 * @description Column settings interface
 */
interface IColumnSettings {
    key: keyof ITableItem
    label: string
}

/**
 * @description Dryers props interface
 */
export interface IDryersProps {
    facilityId: number
    titles: string
    title: string
    itemType: 'container' | 'dryer'
}

/**
 * @description Filter Details Table
 * @returns 
 */
export const FilterDetailsTable: FC<IDryersProps> = ({ facilityId, title, titles, itemType }) => {
    const { isLoading, getContainers, getDryers } = useFilterAdminApi()
    const [tableItems, setTableItems] = useState<ITableItem[]>([])
    const [modalState, setModalState] = useState<{ isOpen: boolean; item?: ITableItem }>({ isOpen: false })
    const [forceRefresh, setForceRefresh] = useState(false)

    /**
     * @description Fetch items
     * @param facilityId
     */
    useEffect(() => {
        const fetchItems = async () => {
            const response = itemType === 'container' ? await getContainers(facilityId) : await getDryers(facilityId)

            if (Array.isArray(response)) {
                const mappedItem: ITableItem[] = response.map((x: any) => {
                    return {
                        id: x.id,
                        name: x.name,
                        status: x.status,
                        rowId: x.id.toString(),
                    }
                })
                setTableItems(mappedItem)
                setForceRefresh(false)
            }
        }
        if (facilityId > 0 || forceRefresh === true) fetchItems()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facilityId, forceRefresh])

    /**
     * @description Handle row click
     * @param rowId 
     */
    const onRowClick = (rowId: string) => {
        const item = tableItems.find((x) => x.id === Number(rowId))
        setModalState({ isOpen: true, item: item })
    }

    /**
     * @description Column settings
     */
    const columnData: IColumnSettings[] = [
        { key: 'id', label: 'id' },
        { key: 'name', label: t('name') },
        { key: 'status', label: t('facilityForm.status') },
    ]

    /**
     * @description Render loading spinner
     */
    if (isLoading) {
        return (
            <div>
                <Spinner color="secondary" size="medium" /> Loading....
            </div>
        )
    }

    /**
     * @description Render table
     */
    return (
        <>
            {modalState.isOpen && (
                <FilterDetailTableModal
                    facilityId={facilityId}
                    itemType={itemType}
                    isOpen={modalState.isOpen}
                    item={modalState.item}
                    headerText={title}
                    onClose={() => {
                        setForceRefresh(true)
                        setModalState({ isOpen: false })
                    }}
                />
            )}

            <div className="mdc-layout-grid remove-padding padding-top-30">
                <div className="mdc-layout-grid__inner remove-padding">
                    <div className="mdc-layout-grid__cell--span-6">
                        <h4 className="text">{titles}</h4>
                    </div>
                    <div
                        className="mdc-layout-grid--align-right 
                                        mdc-layout-grid__cell--span-6 
                                        mdc-layout-grid__cell--align-bottom"
                    >
                        <Button
                            className="margin-bottom-15"
                            onClick={() => setModalState({ isOpen: true })}
                            label={`${t('add')} ${title}`}
                            icon="add"
                        />
                    </div>
                </div>

                <div className="mdc-layout-grid__cell--span-12">
                    <Table
                        columnData={columnData}
                        rowData={tableItems}
                        onRowClickCallback={onRowClick}
                        onRowClick={true}
                    />
                </div>
            </div>
        </>
    )
}

export default FilterDetailsTable
