import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { useRouteConfiguration } from 'hooks/useRouteConfiguration'
import Logout from 'pages/logout/Logout'

export const DefaultRoutes: React.FC = () => {
    const { allRoutes } = useRouteConfiguration()
    return (
        <Routes>
            {/* <Route path="/" element={<Navigate to="/home" />} /> */}

            {Object.entries(allRoutes).map(([key, route]) => (
                <Route key={key} path={route.path} element={route.component} />
            ))}
            <Route key="logout" path="/logout" element={<Logout />} />
        </Routes>
    )
}
