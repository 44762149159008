import React, { useEffect, useCallback, useState, useContext } from 'react'
import { Button, Dropdown, IHandleValueChangedArgs, Table, Spinner } from 'lm2storybook'
import useFetch from 'hooks/useFetch'
import { resourceUrl } from 'constants/apiconstants'
import { DryingResponse, DryingsResponse, DryingTableData } from 'types/dryings'
import { getDateAsLocaleString } from 'utils/dateUtil'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import IPutOptions from 'interfaces/IPutOptions'
import { DryingUpdateRequest } from 'classes/dryingUpdateRequest'
import { columnData } from './columnData'
import { useColumnSettings } from './useTableSettings'
import { MeasurementNames } from './measurementNames'
import { FilterContext } from 'context/FilterContext'
import ConfirmDeleteModal from './confirmDeleteModal'
import { LanguageIdTypes } from 'interfaces/ILanguageId'
import { t } from 'configs/i18n'
import { TestBlob } from 'components/TestBlob'
import RenderToolTip from 'components/renderToolTip'

/**
 * @description Finds measurement by name
 * @param measurements
 * @param name
 * @returns
 */
const findMeasurement = (measurements: DryingResponse['measurements'], name: MeasurementNames) =>
    measurements.find((x) => x.name === name)

/**
 * @description Transforms data from backend to data that can be used in table
 * @param dryingsResponse
 * @returns
 */

const transformData = (dryingsResponse: DryingsResponse) => {
    const findMeasurementValue = (measurements: DryingResponse['measurements'], name: MeasurementNames) =>
        findMeasurement(measurements, name)?.value

    if (!dryingsResponse.dryings) {
        return []
    }
    /**
     * @description Defines table rows
     */
    const tableTows = dryingsResponse.dryings.map((x) => {
        const dataRow: DryingTableData = {
            id: x.id,
            articleName: x.article.name,
            container: x.container?.name,
            setPoint: findMeasurementValue(x.measurements, MeasurementNames.SetPoint),
            dryerName: x.dryer.name,
            isPartialDrying: x.isPartialDrying,
            comment: x.comment,
            grainTempCoolerZone: findMeasurementValue(x.measurements, MeasurementNames.TempCoolingZone),
            facilityName: x.facility.name,
            startDate: getDateAsLocaleString(x.startDate),
            articleMoistureTargetLevel: findMeasurementValue(
                x.measurements,
                MeasurementNames.ArticleMoistureTargetLevel
            ),
            overriddenTargetMoisture: findMeasurementValue(x.measurements, MeasurementNames.OverriddenTargetMoisture),
            grainTemp: findMeasurementValue(x.measurements, MeasurementNames.GrainTemp),
            grainTempDryingZone: findMeasurementValue(x.measurements, MeasurementNames.TempDryingZone),
            moistureLevel: findMeasurementValue(x.measurements, MeasurementNames.MoistureLevel),
            protein: findMeasurementValue(x.measurements, MeasurementNames.Protein),
            specificWeight: findMeasurementValue(x.measurements, MeasurementNames.SpecificWeight),
        }
        return dataRow
    })
    return tableTows
}

/**
 * @description Defines pagination state
 */
interface IPaginationState {
    pageNumber: number
    pageSize: number
    isMoreDataToLoad: boolean
}

/**
 * @description Defines initial pagination state
 */
const initPaginationState: IPaginationState = {
    pageSize: 20,
    pageNumber: 1,
    isMoreDataToLoad: true,
}

/**
 * @description Defines props for component
 */
export interface IPreviousDryingProps {
    editable?: boolean
    sorting?: boolean
    deleteEnabled?: boolean
    article?: string
    queryString?: string
}

export interface IDeleteModalState {
    active: boolean
    id?: string
}

interface IDryingUpdateRequestState {
    updatedRow: DryingUpdateRequest
    id: number
    isValid?: boolean
}

/**
 * @description Defines props for component
 * @param param0
 * @returns
 */
const PreviousDryings: React.FC<IPreviousDryingProps> = ({
    editable = false,
    sorting = false,
    deleteEnabled = false,
    article = "",
    queryString = "",
}) => {
    const EMPTY_QUERY = ''

    /**
     * @description Defines states
     * @param tableData
     * @param dryingsResponse
     * @param updatedTableData
     * @param paginationState
     * @param previousQuery
     * @param deleteModalState
     * @param isSaving
     * @param filterItems
     * @param selectedFilter
     * @param forceReloadPreviousDryings
     */
    const [tableData, setTableData] = useState<DryingTableData[]>([])
    const [dryingsResponse, setDryingsResponse] = useState<DryingResponse[]>([])
    const [updatedTableData, setUpdatedTableData] = useState<IDryingUpdateRequestState[]>([])
    const [paginationState, setPaginationState] = useState<IPaginationState>(initPaginationState)
    const [previousQuery, setPreviousQuery] = useState<string>(EMPTY_QUERY)
    const { forceReloadPreviousDryings, setForceReloadPreviousDryings, setModalState, selectedFilter } =
        useContext(FilterContext)
    const [deleteModalState, setDeleteModalState] = useState<IDeleteModalState>({ active: false })
    const [isSaving, setSavingChanges] = useState<boolean>(false)
    const [showTableErrors, setShowTableErrors] = useState<boolean>(false)
    const { get, isLoading, put } = useFetch()
    const columnSettings = useColumnSettings()

    const filterItemsBasedOnArticle = (data: DryingTableData[]): DryingTableData[] => {
        if (article.length > 0) {
            return data.filter((x) => x.articleName === article)
        }
        return data
    }

    /**
     * @description Fetches data from backend
     * @returns void
     */
    const fetchData = useCallback(async () => {
        const result: DryingsResponse = await get(
            resourceUrl(
                `dryings?PageSize=${paginationState.pageSize}&PageNumber=${paginationState.pageNumber}${queryString}`
            )
        )

        if (!result) {
            return
        }
        setDryingsResponse(result.dryings)
        const transformedData = transformData(result)
        let data: DryingTableData[]

        // Only append data if the query hasn't changed
        if (queryString !== previousQuery) {
            setPreviousQuery(queryString)
            data = transformedData;
        } else {
            data = tableData.concat(transformedData)
        }

        setTableData(data)
        setPaginationState({
            ...paginationState,
            isMoreDataToLoad: transformedData.length >= initPaginationState.pageSize,
        })

        // Don't include tableData or get, it will create unwanted updates
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationState.pageNumber, paginationState.pageSize, queryString])

    /**
     * @description Fetches data when component is mounted
     * @returns void
     */
    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        if (forceReloadPreviousDryings) {
            setTableData([])
            fetchData()
            setForceReloadPreviousDryings(false)
        }

        // Don´t include "fetchData" in dependency array, it will trigger reload
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceReloadPreviousDryings])

    /**
     * Handle changes made in the table
     * @param args {IHandleValueChangedArgs}
     * @returns void
     */
    const handleOnValueChanged = async (args: IHandleValueChangedArgs) => {
        const tableDataRow = tableData.find((x) => x.id === Number(args.id))
        const orgDataRow = dryingsResponse.find((x) => x.id === Number(args.id))
        const dataRow = { ...tableDataRow }

        if (!dataRow) return
        if (!orgDataRow) return

        const propName = args.name as keyof DryingTableData
        dataRow[propName] = args.value

        // TODO #49060 - This is a temp fix
        // we should rework the whole flow so it's more clear what is being updated
        const tableDataFromState = [...tableData]
        const indexOfUpdatedRow = tableDataFromState.findIndex((x) => x.id === Number(args.id))
        tableDataFromState[indexOfUpdatedRow] = dataRow as DryingTableData
        setTableData(tableDataFromState)

        const updatedRequest: IDryingUpdateRequestState = {
            updatedRow: new DryingUpdateRequest(orgDataRow, dataRow as DryingTableData),
            id: orgDataRow.id,
            isValid: args.isValid,
        }

        const indexOfUpdatedRequest = updatedTableData.findIndex((x) => orgDataRow.id === x.id)
        const NO_MATCH_INDEX = -1

        const editTableData =
            indexOfUpdatedRequest === NO_MATCH_INDEX
                ? updatedTableData.concat([updatedRequest])
                : updatedTableData.map((x) => (updatedRequest.id === x.id ? updatedRequest : x))
        setUpdatedTableData(editTableData)
    }

    /**
     * Save change rows to api
     * @returns void
     */
    const saveUpdatedRows = async () => {
        if (!updatedTableData.length) return

        if (updatedTableData.some((x) => x.isValid === false)) {
            setShowTableErrors(true)
            setModalState({ active: true, message: t('previousDryingsSaveError') })
            return
        }

        const failedRequests: IDryingUpdateRequestState[] = []

        /**
         * @description Save request
         * @param payload {DryingUpdateRequest}
         */
        const doSave = async (updateRequestState: IDryingUpdateRequestState) => {
            const options: IPutOptions = {
                method: 'PUT',
                body: JSON.stringify(updateRequestState.updatedRow),
            }
            const response = await put(resourceUrl(`dryings/${updateRequestState.id}`), options)
            const success = typeof response === 'object' && response.errorMessage == null

            if (!success) {
                failedRequests.push(updateRequestState)
            } else {
                setSavingChanges(false)
            }
        }

        /**
         * @description Save all requests
         * @returns void
         */
        await Promise.all(
            updatedTableData.map(async (changeRow) => {
                setSavingChanges(true)
                await doSave(changeRow)
            })
        )

        if (failedRequests.length) {
            setUpdatedTableData([...failedRequests])
            setModalState({
                active: true,
                message: t('alertFailedSavingDrying'),
                title: t('alertDefaultTitle'),
                showCloseButton: true,
                closeButtonText: t('closePopUp'),
            })
        } else {
            setShowTableErrors(false)
            setUpdatedTableData([])
            setForceReloadPreviousDryings(true)
            setModalState({
                active: true,
                message: t('alertDryingSaved'),
                title: t('alertDefaultTitle'),
                showCloseButton: true,
                closeButtonText: t('closePopUp'),
            })
        }
    }

    /**
     * @description Fetches more data from backend
     * @returns void
     */
    const loadMoreData = () => {
        const { pageNumber } = paginationState
        setPaginationState({ ...paginationState, pageNumber: pageNumber + 1 })
    }

    /**
     * @description Shows loading text and spinner if data is loading and no data is loaded
     */
    if (isLoading && tableData.length === 0) {
        return (
            <div>
                <Spinner color="secondary" size="medium" /> Loading....
            </div>
        )
    }

    /**
     * @description set Delete Modal state
     * @param id {string}
     */
    const handelOnDelete = (id: string): void => {
        setDeleteModalState({ active: true, id: id })
    }

    /**
     * @description Deletes a drying - Force reloads previous dryings & closes modal
     * @returns void
     */
    const handleDeleteConfirm = async () => {
        setForceReloadPreviousDryings(true)
        setDeleteModalState({ active: false })
    }

    /**
     * @description Closes delete modal
     * @returns void
     */
    const handleDeleteCancel = async () => {
        setDeleteModalState({ active: false })
    }

    /**
     * @description Sets boolean values to string
     * @param {Object} tableData
     * @returns {Object} newtableData
     */
    const parseBooleanValue = (tableData: any[]) => {
        const newTableData = tableData.map((item: any) => {
            const newItem = { ...item }
            Object.keys(newItem).forEach((key) => {
                if (newItem[key] === true) {
                    newItem[key] = t('yes')
                }
            })
            return newItem
        })
        return newTableData
    }

    const tableSettings = {
        columnSettings: columnSettings,
        deleteEnabled: deleteEnabled,
        renderMethods: {
            comment: (input: string) => RenderToolTip(input),
        },
    }

    /**
     * @description Shows table
     */
    return (
        <div className="table-data-presentation">
            <div className="mdc-layout-grid remove-padding padding-bottom-20">
                <div className="mdc-layout-grid__inner">
                    <div className="mdc-layout-grid__cell--span-7">
                        <h1>
                            {t('listDryingsTitle')} <TestBlob />
                        </h1>
                    </div>

                    {editable && (
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-5
                            mdc-layout-grid--align-right mdc-layout-grid__cell--align-bottom">
                            <Button
                                className="margin-top-4 align-bottom prev-button"
                                disabled={!updatedTableData.length ? true : false}
                                icon="save"
                                label={t('saveEdit')}
                                onClick={saveUpdatedRows}
                                primary
                                size="medium"
                                isLoading={isSaving === true ? true : false}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className="section-container">
                <Table
                    onDeletedCallback={handelOnDelete}
                    columnData={columnData.map((column) => {
                        column.label = t(column.label as keyof LanguageIdTypes)
                        return column
                    })}
                    rowData={parseBooleanValue(filterItemsBasedOnArticle(tableData))}
                    sorting={sorting}
                    editable={editable}
                    settings={tableSettings}
                    onValueChanged={handleOnValueChanged}
                    showErrors={showTableErrors}
                ></Table>
            </div>
            <div className="section-container center sticky-footer">
                <div className="mdc-layout-grid">
                    <div className="mdc-layout-grid__inner remove-padding">
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6
                            mdc-layout-grid--align-right mdc-layout-grid__cell--align-bottom">
                            <Button
                                label={t('loadMore')}
                                disabled={!paginationState.isMoreDataToLoad}
                                onClick={loadMoreData}
                                icon="cloud_download"
                                isLoading={isLoading ? true : false}
                            />
                        </div>
                        {editable && (
                            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6
                                mdc-layout-grid--align-left mdc-layout-grid__cell--align-bottom">
                                <Button
                                    className="margin-top-4 align-bottom prev-button"
                                    disabled={!updatedTableData.length ? true : false}
                                    icon="save"
                                    label={t('saveEdit')}
                                    onClick={saveUpdatedRows}
                                    primary
                                    size="medium"
                                    isLoading={isSaving === true ? true : false}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {deleteModalState.active && (
                <ConfirmDeleteModal
                    onClose={handleDeleteCancel}
                    onConfirm={handleDeleteConfirm}
                    settings={{ isOpen: deleteModalState.active, id: deleteModalState.id }}
                />
            )}
        </div>
    )
}

export default withAuthenticationRequired(PreviousDryings)
