import { t } from 'configs/i18n'
import { FilterContext } from 'context/FilterContext'
import useFilterAdminApi from 'hooks/useFilterAdminApi'
import { Button } from 'lm2storybook'
import { FilterUpsertRequest } from 'models/api/filter'
import { FC, useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ControllerInput } from './ControllerInput'
import { ControllerInputStatus } from './ControllerInputStatus'
import { FilterDetailsTable } from './FilterDetailsTable'

/**
 * @description Filter Details Props
 */
export interface IFilterDetailsProps {
    facilityId?: number
}

/**
 * @description Filter Details
 */
const defaultFormValues: FilterUpsertRequest & { id: number } = {
    name: '',
    externalId: 0,
    region: '',
    status: 0,
    id: 0,
}

/**
 * @description Filter Details
 * @returns
 */
export const FilterDetails: FC<IFilterDetailsProps> = ({ facilityId }) => {
    const { isLoading, fetchFilter, updateFilter, createFilter } = useFilterAdminApi()
    const [forceRefresh, setForceRefresh] = useState(false)
    const [filterIdState, setFilterIdState] = useState<number | undefined>()
    const { setModalState } = useContext(FilterContext)
    const methods = useForm({
        mode: 'onTouched',
        defaultValues: defaultFormValues,
    })

    /**
     * @description Submit form
     */
    const {
        handleSubmit,
        reset,
        formState: { isSubmitting },
        getValues,
    } = methods

    /**
     * @description Set Filter state
     */
    useEffect(() => {
        setFilterIdState(facilityId)
    }, [facilityId])

    /**
     * @description Fetch fetchFilter like Filter
     */
    useEffect(() => {
        const doFetch = async () => {
            if (!filterIdState) {
                reset(defaultFormValues)
                return
            }
            const facility = await fetchFilter(filterIdState)
            reset(facility)
            setForceRefresh(false)
        }
        doFetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterIdState, forceRefresh])

    /**
     * @description Submit form
     * @param args
     * @returns void
     */
    const submitForm = async (args?: any) => {
        const formValues = getValues()
        const isCreateMode = !filterIdState

        const payload: FilterUpsertRequest = {
            name: formValues.name,
            externalId: formValues.externalId,
            region: formValues.region,
            status: formValues.status,
        }
        const response = isCreateMode ? await createFilter(payload) : await updateFilter(filterIdState, payload)
        const success = typeof response === 'object' && response.errorMessage == null

        /**
         * @description Handle invalid form
         */
        if (!success) {
            setModalState({
                active: true,
                message: response.errorMessage,
                title: t('facilityForm.saveError'),
                showCloseButton: true,
                closeButtonText: t('closePopUp'),
            })
            return
        }

        /**
         * @description Handle valid form
         */
        isCreateMode ? setFilterIdState(response.id) : setForceRefresh(true)

        /**
         * @description Show success message
         * @returns void
         */
        setModalState({
            active: true,
            message: t('facilityForm.saveSuccess'),
            title: t('alertDefaultTitle'),
            showCloseButton: true,
            closeButtonText: t('closePopUp'),
        })
    }

    /**
     * @description Handle invalid form
     * @param args
     * @returns void
     */
    const handleInValidForm = (args?: any) => {
        setModalState({
            active: true,
            message: t('alertInvalidForm'),
            title: t('alertDefaultTitle'),
            showCloseButton: true,
            closeButtonText: t('closePopUp'),
        })
    }

    /**
     * @description Render
     */
    return (
        <div className="facility-details remove-padding">
            <h4 className="padding-bottom-20">
                {t('facility')} {methods.control._formValues.name}
            </h4>
            <FormProvider {...methods}>
                <form>
                    <div className="mdc-layout-grid remove-padding">
                        <div className="mdc-layout-grid__inner remove-padding">
                            {/* id*/}
                            <ControllerInput label={t('facilityForm.facilityId')} name={'id'} disabled />
                            {/* name*/}
                            <ControllerInput
                                label={t('facilityForm.facilityName')}
                                rules={{
                                    required: { value: true, message: t('facilityForm.facilityNameRequired') },
                                }}
                                name={'name'}
                            />
                            {/* region*/}
                            <ControllerInput
                                label={t('facilityForm.region')}
                                rules={{ required: { value: true, message: t('facilityForm.regionRequired') } }}
                                name={'region'}
                            />
                            {/* externalId*/}
                            <ControllerInput
                                label={t('facilityForm.externalId')}
                                rules={{
                                    required: { value: true, message: t('facilityForm.externalIdRequired') },
                                }}
                                name={'externalId'}
                            />
                            {/* status*/}
                            <ControllerInputStatus />
                            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--align-bottom mdc-layout-grid--align-right">
                                <Button
                                    size="medium"
                                    label={filterIdState ? t('editFacility') : t('saveFacility')}
                                    icon="save"
                                    onClick={handleSubmit(submitForm, handleInValidForm)}
                                    disabled={isSubmitting || isLoading}
                                    type="submit"
                                    isLoading={isSubmitting || isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
            <div className="mdc-layout-grid remove-padding padding-top-30">
                <div className="mdc-layout-grid__inner remove-padding">
                    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">
                        <FilterDetailsTable
                            itemType="dryer"
                            title={t('dryer')}
                            titles={t('dryers')}
                            facilityId={filterIdState ?? 0}
                        />
                    </div>
                    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">
                        <FilterDetailsTable
                            itemType="container"
                            title={t('containers')}
                            titles={t('containers')}
                            facilityId={filterIdState ?? 0}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
