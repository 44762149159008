/**
 * @description Defines columns names
 */
export enum columnNames {
    id = 'id',
    startDate = 'startDate',
    dryerName = 'dryerName',
    facilityName = 'facilityName',
    articleName = 'articleName',
    articleMoistureTargetLevel = 'articleMoistureTargetLevel',
    overriddenTargetMoisture = 'overriddenTargetMoisture',
    moistureLevel = 'moistureLevel',
    grainTemp = 'grainTemp',
    grainTempCoolerZone = 'grainTempCoolerZone',
    grainTempDryingZone = 'grainTempDryingZone',
    setPoint = 'setPoint',
    specificWeight = 'specificWeight',
    protein = 'protein',
    container = 'container',
    isPartialDrying = "isPartialDrying",
    comment = 'comment',    
};