import { FilterContext } from 'context/FilterContext'
import { Modal } from 'lm2storybook'
import * as React from 'react'
import { useContext } from 'react'

/**
 * @description Modal props
 */
export interface IModalProps {
    active: boolean
    message?: string
    title?: string
    closeButtonText?: string
    showCloseButton?: boolean
}

/**
 * @description Modal that can be used from any component in the application
 * @returns Modal
 */
const ModalContainer: React.FC = () => {
    const {
        modalState: { active, message, title, showCloseButton, closeButtonText },
        setModalState,
    } = useContext(FilterContext)
    
    return (
        <>
            {active && (
                <Modal
                    headerText={title ?? ''}
                    isOpen={active}
                    onClose={() => setModalState({ active: false })}
                    showCloseButton={showCloseButton ? showCloseButton : false}
                    closeButtonText={closeButtonText ? closeButtonText : ''}
                >
                    <div>{message}</div>
                </Modal>
            )}
        </>
    )
}

export default ModalContainer
