import Base from 'components/layout/base/Base'
import { t } from 'configs/i18n'
import useFilterAdminApi from 'hooks/useFilterAdminApi'
import React, { useEffect, useState } from 'react'
import { Table, Spinner, Button } from 'lm2storybook'
import { FilterDetails } from './FilterDetails'
import useAuthUser from 'hooks/useAuthUser'
import { useNavigate } from 'react-router'

/**
 * @description Facility Item Interface
 */
interface IfacilityItem {
    id: number
    externalId: number
    name: string
    region: string
    rowId?: string
}

/**
 * @description Column settings interface
 */
interface IColumnSettings {
    key: keyof IfacilityItem
    label: string
}

/**
 * @description Admin page
 * @returns {React.JSX.Element}
 */
const Admin = (): React.JSX.Element => {
    const { isAuthenticated, isAdmin } = useAuthUser()
    const navigateTo = useNavigate()
    const { isLoading, fetchFilters } = useFilterAdminApi()
    const [filters, setFilter] = useState<IfacilityItem[]>([])
    const [filterDetailsState, setFilterDetailsState] = useState<{ isOpen: boolean; facilityId: number }>({
        isOpen: false,
        facilityId: 0,
    })

    /**
     * @description Fetch filter
     */
    useEffect(() => {
        const doFetch = async () => {
            const fetchFilterData = await fetchFilters()
            const items: IfacilityItem[] = fetchFilterData.map((x: { id: any; externalId: any; name: any; region: any }) => {
                return {
                    id: x.id,
                    externalId: x.externalId,
                    name: x.name,
                    region: x.region,
                }
            })

            setFilter(items)
        }
        doFetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * @description open Modal on row click
     * @param rowId
     */
    const openModalOnRowClick = (rowId: string) => {
        setFilterDetailsState({ isOpen: true, facilityId: Number(rowId) })
    }

    /**
     * @description Column settings
     */
    const columnData: IColumnSettings[] = [
        {
            key: 'id',
            label: 'id',
        },
        {
            key: 'externalId',
            label: 'External id',
        },
        {
            key: 'name',
            label: 'Namn',
        },
        {
            key: 'region',
            label: 'Region',
        },
    ]

    /**
     * @description Redirect to home if not authenticated or not admin
     * */
    useEffect(() => {
        if (isAuthenticated && !isAdmin) {
            navigateTo('/')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, isAdmin])

    /**
     * @description Render
     */
    return (
        <Base pageName="home">
            <div className="login-wrapper">
                {isAuthenticated && !isLoading ? (
                    <>
                        <div className="mdc-layout-grid remove-padding ">
                            <div className="mdc-layout-grid__inner remove-padding">
                                <div className="mdc-layout-grid__cell--span-2">
                                    <h1>{t('adminPageTitle')}</h1>
                                </div>
                                {filterDetailsState.isOpen && (
                                    <div className="mdc-layout-grid--align-right mdc-layout-grid__cell--span-10 mdc-layout-grid__cell--align-bottom">
                                        <Button
                                            onClick={() => setFilterDetailsState({ isOpen: false, facilityId: 0 })}
                                            label={t('goBackToList')}
                                            icon="arrow_back"
                                        />
                                    </div>
                                )}

                                {/** ====  TODO Temp code until real implementation ==== */}
                                {!filterDetailsState.isOpen && (
                                    <div className="mdc-layout-grid--align-right mdc-layout-grid__cell--span-10 mdc-layout-grid__cell--align-bottom">
                                        <Button
                                            onClick={() => setFilterDetailsState({ isOpen: true, facilityId: 0 })}
                                            label={t('addFacility')}
                                            icon="add"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="padding-top-30">
                            {filterDetailsState.isOpen && (
                                <FilterDetails facilityId={filterDetailsState.facilityId} />
                            )}
                        </div>

                        {filterDetailsState.isOpen === false && (
                            <>
                                <h4 className="text">{t('facilities')}</h4>
                                <Table
                                    columnData={columnData}
                                    rowData={filters}
                                    onRowClickCallback={(rowId: string) => openModalOnRowClick(rowId)}
                                    onRowClick={true}
                                />
                            </>
                        )}
                    </>
                ) : (
                    <div>
                        <Spinner color="secondary" size="medium" /> Loading....
                    </div>
                )}
            </div>
        </Base>
    )
}

export default Admin
