import { createContext, useState } from 'react'
import { Filter } from '../models/api/filter'
import { IModalProps } from 'components/modal/modalContainer'

export default interface IContextProps {
    children: React.ReactNode
}

/**
 * @description Filter Context - Create a new context for the selected Filter
 */
export const FilterContext = createContext<{
    forceReloadPreviousDryings?: boolean
    setForceReloadPreviousDryings: (forceReload: boolean) => void
    selectedFilter?: Filter
    setSelectedFilter: (facility?: Filter) => void
    modalState: IModalProps
    setModalState: (state: IModalProps) => void
        }>({
            modalState: { active: false },
            setSelectedFilter: () => {},
            setForceReloadPreviousDryings: () => {},
            setModalState: () => {},
        })

        
// Define a new component that wraps other components and provides the context
/**
 * @description Facility Provider - Define a new component that wraps other components and provides the context
 */
export const FilterProvider = ({ children }: IContextProps) => {
    // Define state for the selected Facility
    const [selectedFilter, setSelectedFilter] = useState<Filter>()
    const [forceReloadPreviousDryings, setForceReloadPreviousDryings] = useState<boolean>()
    const [modalState, setModalState] = useState<IModalProps>({ active: false })

    // Provide the selected Facility and the ability to update it to child components
    return (
        <FilterContext.Provider
            value={{
                selectedFilter,
                setSelectedFilter,
                forceReloadPreviousDryings,
                setForceReloadPreviousDryings,
                modalState,
                setModalState,
            }}
        >
            {children}
        </FilterContext.Provider>
    )
}
