import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import { Auth0Provider } from '@auth0/auth0-react'
import { ErrorBoundary } from 'react-error-boundary'
import { App } from './App'
import Error from './pages/errorPage/ErrorPage'
import { configuration } from './configs/config'

/**
 * @description CSS
 */
import 'lm2storybook/dist/css/lm2storybook/lm2storybook.css'
import './assets/scss/index.scss'

/**
 * @constant container
 * @description Get the container element from the DOM
 */
const container = document.getElementById('dryingLogApp')

/**
 * @constant root
 * @description Create a root element
 * @borrows ReactDOMClient.createRoot
 */
const root = ReactDOMClient.createRoot(container!)

/**
 * @module index
 * @description Main entry point for the application
 * @borrows Auth0Provider, ErrorBoundary, App, Error
 * @borrows configuration
 * @borrows container, root
 * @returns void
 */
root.render(
    <React.StrictMode>
        <Auth0Provider
            domain={configuration.authDomain}
            clientId={configuration.authClientId}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: configuration.authAudience,
            }}
            cacheLocation="localstorage"
        >
            <ErrorBoundary FallbackComponent={Error}>
                <App />
            </ErrorBoundary>
        </Auth0Provider>
    </React.StrictMode>
)
