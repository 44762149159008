import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const Logout = (): JSX.Element => {
    const { logout } = useAuth0()

    useEffect(() => {
        const logoutWithRedirect = () =>
            logout({
                logoutParams: {
                    returnTo: window.location.origin,
                },
            })
        logoutWithRedirect()
    }, [logout])

    return <></>
}

export default Logout
