import { Button, Modal } from 'lm2storybook'
import { FormProvider, useForm } from 'react-hook-form'
import { ITableItem } from './FilterDetailsTable'
import { ControllerInputStatus } from './ControllerInputStatus'
import { ControllerInput } from './ControllerInput'
import { useContext, useEffect } from 'react'
import { t } from 'configs/i18n'
import { FilterContext } from 'context/FilterContext'
import useFilterAdminApi from 'hooks/useFilterAdminApi'

export interface IProps {
    isOpen: boolean
    item?: ITableItem
    headerText?: string
    onClose: () => void
    itemType: 'container' | 'dryer'
    facilityId: number
}

const defaultFormValues = {
    id: 0,
    name: '',
    status: '',
    rowId: '',
}

export const FilterDetailTableModal: React.FC<IProps> = ({
    item,
    isOpen,
    headerText,
    onClose,
    itemType,
    facilityId,
}) => {
    const { createContainer, updateContainer, updateDryer, createDryer } = useFilterAdminApi()

    const { setModalState } = useContext(FilterContext)
    const methods = useForm({
        mode: 'onTouched',
        defaultValues: defaultFormValues,
    })

    const {
        handleSubmit,
        reset,
        formState: { isSubmitting },
        getValues,
    } = methods

    useEffect(() => {
        if (item) {
            reset(item)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    const updateItem = async (payload: any) => {
        return itemType === 'container' ? updateContainer(payload.id, payload) : updateDryer(payload.id, payload)
    }
    const createItem = async (payload: any) => {
        return itemType === 'container' ? createContainer(payload) : createDryer(payload)
    }

    const submitForm = async (args?: any) => {
        const formValues = getValues()
        const isCreateMode = formValues.id === 0

        const payload = {
            id: formValues.id,
            name: formValues.name,
            status: formValues.status,
            facilityId: facilityId,
        }
        const response = isCreateMode ? await createItem(payload) : await updateItem(payload)
        const success = typeof response === 'object' && response.errorMessage == null

        if (!success) {
            setModalState({
                active: true,
                message: response.errorMessage,
                title: 'Error',
                showCloseButton: true,
                closeButtonText: t('closePopUp'),
            })
            return
        }

        onClose()
    }

    const handleInValidForm = (args?: any) => {
        setModalState({
            active: true,
            message: t('alertInvalidForm'),
            title: t('alertDefaultTitle'),
            showCloseButton: true,
            closeButtonText: t('closePopUp'),
        })
    }
    return (
        <Modal key={'detailsModal'} headerText={headerText ?? ''} isOpen={isOpen} onClose={onClose} size="medium">
            <FormProvider {...methods}>
                <form id="form-modal">
                    <div className="mdc-layout-grid remove-padding">
                        <div className="mdc-layout-grid__inner remove-padding">
                            {/* id*/}
                            <ControllerInput label={'Id'} name={'id'} disabled />
                            {/* name*/}
                            <ControllerInput
                                label={t('name')}
                                rules={{
                                    required: { value: true, message: 'Required' },
                                }}
                                name={'name'}
                            />

                            {/* status*/}
                            <ControllerInputStatus />
                            <div
                                className="mdc-layout-grid__cell--span-12 
                                mdc-layout-grid__cell mdc-layout-grid__cell--align-bottom 
                                mdc-layout-grid--align-right"
                            >
                                <Button
                                    size="medium"
                                    label={t('saveChanges')}
                                    icon="save"
                                    onClick={handleSubmit(submitForm, handleInValidForm)}
                                    disabled={isSubmitting}
                                    isLoading={isSubmitting}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    )
}
