import { useTranslation } from 'react-i18next'
import 'i18n.js'
import Base from '../../components/layout/base/Base'
import { useSearchParams } from 'react-router-dom'
import { Button } from 'lm2storybook'
import { useAuth0 } from '@auth0/auth0-react'

/**
 * @description Show error message
 * @returns
 */
const ErrorPage = () => {
    const { t } = useTranslation()
    const [searchParams] = useSearchParams()
    const error = searchParams.get('error')
    const { loginWithRedirect } = useAuth0()

    /**
     * @description Show error message
     */
    return (
        <div className="error-wrapper">
            <Base pageName="ErrorPage">
                <h1>{t('somethingWrong')}</h1>
                <h3>{t(`${error ? error : t('defaultError')}`)}</h3>
                {error === '401' ? (
                    <>
                        <span className="text">{t('loginDescription')}</span>
                        <p>
                            <Button onClick={() => loginWithRedirect()} label={t('login')} />
                        </p>
                    </>
                ) : (
                    <></>
                )}
            </Base>
        </div>
    )
}

export default ErrorPage
