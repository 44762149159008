import { RouteObject, RouteObjectType } from 'lm2storybook'
import RegisterPage from 'pages/registerPage/RegisterPage'
import AdminPage from 'pages/admin/AdminPage'
import Base from 'components/layout/base/Base'
import PreviousDryings from 'pages/previousDryings/previousDryings'
import { t } from 'i18next'
import HomePage from 'pages/home/HomePage'
import ErrorPage from 'pages/errorPage/ErrorPage'

/**
 * @description Navbar routes
 */
export type NavbarRoutes = 'home' | 'register'
export type RouteKey = NavbarRoutes | 'error' | 'admin'

/**
 * @description Hook to get all routes and navbar routes
 * @returns {allRoutes: RouteObjectType<RouteKey>, navBarRoutes: RouteObjectType<NavbarRoutes>}
 */
export const useRouteConfiguration = () => {
    const home = new RouteObject('/', <div>{<HomePage />}</div>, t('home'))
    const register = new RouteObject('/register', <RegisterPage />, t('register'))
    const admin = new RouteObject('/admin', <AdminPage />, t('admin'))
    const error = new RouteObject('/error', <ErrorPage />, t('error'))

    /**
     * @description All routes
     */
    const allRoutes: RouteObjectType<RouteKey> = {
        home,
        register,
        error,
        admin,
    }

    /**
     * @description Routes to be shown in the navbar
     */
    const navBarRoutes: RouteObjectType<NavbarRoutes> = {
        home,
        register,
    }
    /**
     * @description Routes to be shown in the navbar if you have admin rights
     */
    const navBarRoutesAdmin: RouteObjectType<NavbarRoutes | 'admin'> = {
        home,
        register,
        admin,
    }

    return { allRoutes, navBarRoutes, navBarRoutesAdmin }
}
