import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import sv from "../locales/sv/translation";
import en from "../locales/en/translation";
import { LanguageIdTypes } from "interfaces/ILanguageId";

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

/**
 * Use to get type save language translations
 * @param id 
 * @returns 
 */
export const  t = (id: keyof LanguageIdTypes) => i18n.t(id as any)


/**
 * @description i18n configuration
 * @see https://www.i18next.com/overview/configuration-options
 * @see https://www.i18next.com/overview/api
 * @see https://www.i18next.com/overview/plugins-and-utils
 * @see https://www.i18next.com/overview/configuration-options
 */
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        returnNull: false,
        resources: {
            en: {
                translation: en
            },
            sv: {
                translation: sv
            },
        },
        fallbackLng: 'sv',
        debug: false,
        react: {
            useSuspense: false,
            bindI18n: 'languageChanged',
        },
        load: 'all'
    });

export default i18n;