import { useAuth0 } from '@auth0/auth0-react'
import { configuration } from '../configs/config'

const useAuthUser = () => {
    const { isAuthenticated, user } = useAuth0()
    const isAdmin = user?.[configuration.claimsName+'/Groups']?.includes(configuration.superAdminClaimsValue)

    return { isAuthenticated, user, isAdmin }
}

export default useAuthUser
