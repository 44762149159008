import { DryingCreateRequest } from 'models/api/dryingCreateRequest'
import { DryingResponse, DryingTableData } from 'types/dryings'

export class DryingUpdateRequest implements DryingCreateRequest {
    facilityId: number
    roundTrip: boolean | undefined
    overriddenTargetMoistureLevel: number | undefined
    operator: string
    grainTempCoolerZone: number | undefined
    grainTempDryingZone: number | undefined
    startDate: Date
    dryerId: number
    moistureLevel: number
    specificWeight: number | undefined
    protein: number | undefined
    setPoint: number | undefined
    containerId: number | undefined
    articleNumber: string | undefined
    comment: string | undefined
    isPartialDrying: boolean
    id: number

    /**
     *
     */
    constructor(orgDataRow: DryingResponse, tableDataRow: DryingTableData) {
        this.id = orgDataRow.id
        this.facilityId = orgDataRow.facilityId
        this.roundTrip = orgDataRow.roundTrip
        this.overriddenTargetMoistureLevel = tableDataRow.overriddenTargetMoisture
        this.operator = orgDataRow.createdBy
        this.grainTempCoolerZone = tableDataRow.grainTempCoolerZone
        this.grainTempDryingZone = tableDataRow.grainTempDryingZone
        this.startDate = new Date(tableDataRow.startDate)
        this.dryerId = orgDataRow.dryerId
        this.moistureLevel = Number(tableDataRow.moistureLevel)
        this.specificWeight = tableDataRow.specificWeight
        this.protein = tableDataRow.protein
        this.setPoint = tableDataRow.setPoint
        this.containerId = orgDataRow.containerId
        this.articleNumber = orgDataRow.article?.articleNumber
        this.isPartialDrying = orgDataRow?.isPartialDrying
        this.comment = tableDataRow.comment
    }
}
