import { ITableItemSetting } from 'lm2storybook'
import { columnNames } from './columNames'
import useFormSettings from 'pages/registerPage/useFormSettings'

/**
 * @description Defines columns in table
 * @returns {ITableSetting} tableSettings
 */
export const useColumnSettings = (): ITableItemSetting[] => {
    const settings = useFormSettings()
    const {overriddenTargetMoistureLevel, moistureLevel, coolZone, dryZone} = settings.rules
    const columnSettings: ITableItemSetting[] = [
        {
            settingsKey: columnNames.id,
            fieldType: 'text',
            isDisabled: true,
        },
        {
            settingsKey: columnNames.startDate,
            fieldType: 'text',
            hasLabel: false,
            isDisabled: false,
        },
        {
            settingsKey: columnNames.dryerName,
            fieldType: 'text',
            hasLabel: false,
            isDisabled: true,
        },
        {
            settingsKey: columnNames.facilityName,
            fieldType: 'text',
            hasLabel: false,
            isDisabled: true,
        },
        {
            settingsKey: columnNames.articleName,
            fieldType: 'text',
            hasLabel: false,
            isDisabled: true,
        },
        {
            settingsKey: columnNames.articleMoistureTargetLevel,
            fieldType: 'text',
            hasLabel: false,
            isDisabled: true,
        },
        {
            settingsKey: columnNames.overriddenTargetMoisture,
            fieldType: 'number',
            hasLabel: false,
            isDisabled: false,
            min: overriddenTargetMoistureLevel.min,
            max: overriddenTargetMoistureLevel.max,
        },
        {
            settingsKey: columnNames.moistureLevel,
            fieldType: 'number',
            hasLabel: false,
            isDisabled: false,
            min: moistureLevel.min,
            max: moistureLevel.max,
        },
        {
            settingsKey: columnNames.grainTemp,
            fieldType: 'number',
            hasLabel: false,
            isDisabled: false,
        },
        {
            settingsKey: columnNames.grainTempCoolerZone,
            fieldType: 'number',
            hasLabel: false,
            isDisabled: false,
            min: coolZone.min,
            max: coolZone.max
        },
        {
            settingsKey: columnNames.grainTempDryingZone,
            fieldType: 'number',
            hasLabel: false,
            isDisabled: false,
            min: dryZone.min,
            max: dryZone.max
        },
        {
            settingsKey: columnNames.setPoint,
            fieldType: 'text',
            hasLabel: false,
            isDisabled: false,
        },
        {
            settingsKey: columnNames.specificWeight,
            fieldType: 'text',
            hasLabel: false,
            isDisabled: false,
        },
        {
            settingsKey: columnNames.protein,
            fieldType: 'text',
            hasLabel: false,
            isDisabled: false,
        },
        {
            settingsKey: columnNames.container,
            fieldType: 'text',
            hasLabel: false,
            isDisabled: false,
        },
        {
            settingsKey: columnNames.comment,
            fieldType: 'text',
            hasLabel: false,
            isDisabled: false,
        },
        {
            settingsKey: columnNames.isPartialDrying,
            fieldType: 'text',
            hasLabel: false,
            isDisabled: true,
        },
       
    ]
    
    return columnSettings
}


