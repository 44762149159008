export type LanguageIdTypes = typeof translation

/**
 * This is the main translation file.
 * Properties added here must also be implemented in other translations files
 */
const translation = {
    '401': 'Verkar som du inte är inloggad',
    '404': 'Sidan verkar inte finnas. Försök igen senare.',
    '500': 'Ser ut som någon tjänst i bakgrunden inte fungerar som den ska. Försök igen senare.',
    addComment: 'Lägg till avvikelse / kommentar',
    addContainer: 'Lägg till behållare',
    addRoundTrip: 'Lägg till rundkörning',
    addSignature: 'Skriv in signatur',
    admin: 'Admin',
    adminPageTitle: 'Administration',
    alertBackendRequestFailed: 'Misslyckades att hämta data från servern. Vänligen försök igen.',
    alertBothContainerAndRoundTripSelected: 'Välj antingen behållare eller rundkörning',
    alertDefaultTitle: 'Meddelande!',
    alertDryingSaved: 'Torkningen är sparad!',
    alertDryingFailedToSave: 'Torkningen gick inte att spara, försök igen',
    alertFacilitySelected: 'Ingen anläggning är vald.',
    alertFailedSavingDrying: 'Misslyckades att spara torkningen!',
    alertNoArticleSelected: 'Ingen artikel vald',
    alertNoContainerOrRoundTripSelected: 'Välj behållare eller rundkörning',
    alertNoDryerSelected: "'Ingen tork är vald.'",
    alertOperatorMissing: 'Operatör/signatur saknas',
    previousDryingsSaveError: 'Det finns fel i tabellen. Rätta till dessa innan du sparar.',
    alertInvalidForm: 'Det finns fel i formuläret. Rätta till dessa innan du sparar.',
    applicationName: 'Torkjournal',
    article: 'Artikel',
    closePopUp: 'Stäng',
    columnLabels: {
        articleMoistureTargetLevel: 'Mål-vh %',
        articleName: 'Artikel',
        comment: 'Kommentar',
        container: 'Behållare',
        dryerName: 'Tork',
        facilityName: 'Anläggning',
        grainTemp: 'Temp spannmål',
        grainTempCoolerZone: 'Spnml.temp kylzon',
        grainTempDryingZone: 'Lufttemp torkzon',
        id: 'ID',
        moistureLevel: 'Vattenhalt %',
        overriddenTargetMoisture: 'Överr mål-vh %',
        protein: 'Protein',
        setPoint: 'Börvärde',
        specificWeight: 'Rymdvikt',
        startDate: 'Starttid',
        isPartialDrying: 'Deltorkning'
    },
    facilities: 'Anläggningar',
    'facilityForm.externalId': 'Extern ID',
    'facilityForm.externalIdPlaceholder': 'Ange ett Extern ID',
    'facilityForm.externalIdRequired': 'Extern ID är obligatoriskt',
    'facilityForm.facilityId': 'Anläggnings-ID',
    'facilityForm.facilityIdPlaceholder': 'Ange ett Anläggnings-ID',
    'facilityForm.facilityIdRequired': 'Anläggnings-ID är obligatoriskt',
    'facilityForm.facilityName': 'Anläggningsnamn',
    'facilityForm.facilityNamePlaceholder': 'Ange ett Anläggningsnamn',
    'facilityForm.facilityNameRequired': 'Anläggningsnamn är obligatoriskt',
    'facilityForm.region': 'Region',
    'facilityForm.regionPlaceholder': 'Ange en Region',
    'facilityForm.regionRequired': 'Region är obligatoriskt',
    'facilityForm.saveError': 'Misslyckades att spara anläggningen!',
    'facilityForm.saveSuccess': 'Anläggningen är sparad!',
    'facilityForm.status': 'Status',
    'facilityForm.statusPlaceholder': 'Ange en Status',
    'facilityForm.statusRequired': 'Status är obligatoriskt',
    'facilityForm.status.active': 'Aktiv',
    'facilityForm.status.inactive': 'Inaktiv',
    comment: 'Kommentar',
    confirmDeleteMessage: 'Vill du radera denna log?',
    coolZone: 'Spannmåltemperatur kylzon',
    dateTime: 'Datum/tid:',
    defaultError: 'Det gick fel, försök igen senare.',
    dryer: 'Tork',
    dryZone: 'Lufttemperatur torkzon',
    facility: 'Anläggning',
    fetchArticleError: 'Kunde inte hämta artiklar',
    home: 'Hem',
    listDryings: 'Journal',
    listDryingsTitle: 'Journal',
    loadMore: 'Hämta mer',
    login: 'Logga in',
    loginDescription: 'Vänligen logga in med din Lantmännen-e-post-adress.',
    loginError: 'Något gick fel med din inloggning, försök igen.',
    loginLoading: 'Laddar...',
    logout: 'Logga ut',
    operator: 'Operatör (Signatur)',
    overriddenTargetWaterContent: 'Överstyrd målvattenhalt',
    placeholderCoolingZone: 'Ange ett värde mellan 0-50',
    placeholderDryingZone: 'Ange ett värde mellan 40-95',
    placeholderMoisterLevel: 'Ange ett värde mellan 5-30',
    placeholderOverriddenTargetWaterContent: 'Ange ett värde mellan 5-30',
    placeholderProtein: 'Ange ett värde',
    placeholderSetPoint: 'Ange ett värde',
    placeholderSpecificWeight: 'Ange ett värde',
    protein: 'Protein',
    register: 'Registrera',
    registerTitle: 'Registrera torkning',
    requiredFields: 'OBS! Obligatoriska fält.',
    roundTrip: 'Rundkörning',
    saveChanges: 'Spara',
    selectArticle: 'Välj artikel',
    selectDrying: 'Välj tork',
    selectFacility: 'Välj anläggning',
    setPoint: 'Börvärde',
    somethingWrong: 'Hoppsan! Något gick fel.',
    specificWeight: 'Rymdvikt',
    systemMessage: 'System-meddelande',
    targetWaterContent: 'Målvattenhalt',
    toContainer: 'Till behållare',
    waterLevel: 'Vattenhalt',
    editFacility: 'Redigera anläggning',
    addFacility: 'Lägg till anläggning',
    saveFacility: 'Spara anläggning',
    saveEdit: 'Spara redigering',
    goBackToList: 'Tillbaka till listan',
    dryers: 'Torkar',
    containers: 'Behållare',
    add: 'Lägg till',
    name: 'Namn',
    isPartialDrying: 'Deltorkning',
    yes: 'Ja',
    no: 'Nej',
}

export default translation
