import { ControllerRenderProps, useFormContext } from 'react-hook-form'
import { Dropdown } from 'lm2storybook'
import { Controller, ControllerFieldState } from 'react-hook-form'
import { t } from 'configs/i18n'
import { FC } from 'react'
import { Item } from 'lm2storybook/dist/stories/interfaces/item'

/**
 * @description Controller Input Status
 * @returns
 */
export const ControllerInputStatus: FC = () => {
    /**
     * @description Form methods
     */
    const methods = useFormContext()
    const {
        control,
        formState: { submitCount },
    } = methods

    /**
     * @description Field is valid
     * @param fieldState
     * @returns
     */
    const fieldIsValid = (fieldState: ControllerFieldState) =>
        !fieldState.isTouched && submitCount === 0 ? undefined : !fieldState.invalid

    /**
     * @description Field error message
     * @param fieldState
     * @returns
     */
    const fieldErrorMessage = (fieldState: ControllerFieldState) => {
        if (!fieldState.isTouched && submitCount === 0) {
            return
        }
        if (submitCount > 0) {
            return fieldState.error?.message
        }
    }

    /**
     * @description Handle status change
     * @param item
     * @param field
     */
    const handleStatusChange = (item: Item | null, field: ControllerRenderProps<any, any>) => {
        if (!item) {
            return;
        }

        field.onChange(item.value)
        field.onBlur()
    }

    /**
     * @description Render
     */
    return (
        <div className="mdc-layout-grid__cell">
            <Controller
                render={({ field, fieldState }) => (
                    <Dropdown
                        isValid={fieldIsValid(fieldState)}
                        label={t('facilityForm.status')}
                        description={`${t('facilityForm.status')}`}
                        id={field.name}
                        data={[
                            {
                                id: '0',
                                name: t('facilityForm.status.active'),
                                value: '0',
                                selected: `${0}` === `${field.value}`,
                            },
                            {
                                id: '1',
                                name: t('facilityForm.status.inactive'),
                                value: '1',
                                selected: `${1}` === `${field.value}`,
                            },
                        ]}
                        onItemClick={(item) => handleStatusChange(item, field)}
                        errorMessage={fieldErrorMessage(fieldState)}
                    />
                )}
                rules={{
                    required: { value: true, message: t('facilityForm.statusRequired') },
                }}
                control={control}
                name="status"
            />
        </div>
    )
}
