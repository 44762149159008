/**
 * @interface BaseProps
 * @description Base Props Interface
 * @property {React.ReactNode} children - Children
 * @property {string} pageName - Page Name
 * @returns {JSX.Element}
 */
interface BaseProps {
    children: React.ReactNode
    pageName: string
}

/**
 * @description Layout - Base component wrapper
 * Set of div containers to wrap the page content
 * @param children
 * @param pageName
 * @returns {React.JSX.Element}
 */
const Base = ({ children, pageName }: BaseProps): React.JSX.Element => (
    <section>
        <div className="base-wrapper">
            <div className="base-inner">
                <div
                    className={`${pageName} base-block mdc-layout-grid`}
                >
                    {children}
                </div>
            </div>
        </div>
    </section>
)

export default Base
