import { t } from 'configs/i18n'
import { ControllerFieldState } from 'react-hook-form'

/**
 * Settings for form validations
 * @returns defaultFormValues, fieldIsValid, rules
 */
const useFormSettings = () => {
    const defaultFormValues = {
        operatorSign: '',
        grainTemperatureDryer: '',
        grainTempCoolerZone: '',
        comment: '',
        overriddenTargetMoistureLevel: '',
        moistureLevel: '',
        specificWeight: '',
        setPoint: '',
        protein: '',
        facilityId: '',
        dryerId: '',
        articleId: '',
        containerId: '',
        isPartialDrying: '',
    }

    const rules = {
        operatorSign: {
            required: { value: true, message: t('alertOperatorMissing') },
        },
        facilityId: {
            required: { value: true, message: t('alertFacilitySelected') },
        },
        dryerId: {
            required: { value: true, message: t('alertNoDryerSelected') },
        },
        articleId: {
            required: { value: true, message: t('alertNoArticleSelected') },
        },
        moistureLevel: {
            min: { value: '5', message: t('placeholderMoisterLevel') },
            max: { value: '30', message: t('placeholderMoisterLevel') },
            required: { value: true, message: t('placeholderMoisterLevel') },
        },
        dryZone: {
            min: { value: '40', message: t('placeholderDryingZone') },
            max: { value: '95', message: t('placeholderDryingZone') },
        },
        coolZone: {
            min: { value: '0', message: t('placeholderCoolingZone') },
            max: { value: '50', message: t('placeholderCoolingZone') },
        },
        containerId: {
            required: { value: true, message: t('alertNoContainerOrRoundTripSelected') },
        },
        overriddenTargetMoistureLevel: {
            min: { value: '5', message: t('placeholderMoisterLevel') },
            max: { value: '30', message: t('placeholderMoisterLevel') },
        },
    }

    const fieldIsValid = (fieldState: ControllerFieldState, submitCount: number) => {
        if (!fieldState.isTouched && submitCount === 0) {
            return undefined
        }
        if (fieldState.invalid) {
            return false
        }
        return true
    }

    const errorMessage = (fieldState: ControllerFieldState, submitCount: number) => {
        if (!fieldState.isTouched  && submitCount === 0) {
            return undefined
        }
        return fieldState.error?.message
    }
    return { defaultFormValues, fieldIsValid, rules, errorMessage }
}
export default useFormSettings
