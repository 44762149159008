
/**
 * Names for measurements
 */
export enum MeasurementNames {
    SetPoint = 'SetPoint',
    TempCoolingZone = 'TempCoolingZone',
    ArticleMoistureTargetLevel = 'ArticleMoistureTargetLevel',
    OverriddenTargetMoisture = 'OverriddenTargetMoisture',
    GrainTemp = 'GrainTemp',
    TempDryingZone = 'TempDryingZone',
    MoistureLevel = 'MoistureLevel',
    SpecificWeight = 'SpecificWeight',
    Protein = 'Protein',
}
